/* /*
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: white;
  color: black;
}
.react-datepicker__day {
  background-color: white;
}
.react-datepicker__day:hover {
  background-color: #d1b962;
  border-radius: 10px;
  height: 2.7rem;
  cursor: pointer;

  color: black;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}
.react-datepicker__day--selected {
  background-color: #ffe586;
  border-radius: 10px;
  height: 2.7rem;

  color: black;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}
.react-datepicker__current-month {
  font-weight: 400;
}
.react-datepicker__day--selected:hover {
  background-color: #d1b962;
}
.react-datepicker__day-name {
  color: #a8a8a8;
}
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 3rem;
}



.Calendar__day {
  border-radius: 10px !important;
  border: 1px solid white;
}

.Calendar__day.-selected {
  border-radius: 10px;
  color: black;
  background-color: red;
}

.-ltr:hover {
  border-radius: 10px;
}


.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border-radius: 10px;
  background-color: white;
}

.custom-today-day {
  color: black !important;
  background-color: #ffe586;
  border: 1px solid #ffe586;
  box-shadow: 1px solid #ffe586 !important;
}
.custom-today-day:hover {
  color: black !important;
  background-color: #ccad3b;
  border: 1px solid #ffe586 !important;
}
.Calendar__day:hover {
  color: black !important;
  border-radius: 10px;

  border: 1px solid #ccad3b !important;
}
.Calendar__yearText {
  font-weight: bold;
}
.Calendar__monthText {
  font-weight: 100;
  font-size: 18px;
}

.custom-today-day::after {
  visibility: hidden; 
}

*/
.mycal{
  background-color: white !important;
  font-size: 10px;
max-width: 760px;
}

.Calendar__monthArrow {
  background-image: url("https://cdn0.iconfinder.com/data/icons/glyphpack/26/nav-arrow-left-512.png") !important;
  width: 7px !important;
  height: 12px !important;
  transform: rotate(90deg) !important;
}

.custom-today-day {
  color: black !important;
  background-color: #ffe586 !important;
  border: none;
  margin-bottom: none;
  box-shadow: 1px solid #ffe586 !important;
  font-style: none;
}
.custom-today-day::after {
  visibility: hidden;
}
.Calendar__day.-ltr {
  min-height: 3.1em !important;
}
.Calendar__day,
.Calendar__day:hover {
  border-radius: 10px !important;
}
.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border: 1px solid var(--primarycolor) !important;
  background-color: white !important;
  color: black !important;
}
div.Calendar__day.-ltr.-selected{
  color: var(--secondarycolor) !important;
}
div.Calendar__day.-ltr.-today{
  color: var(--secondarycolor) !important;
}
.Calendar__monthText {
  font-weight: 100 !important;
  padding: 0 !important;
}

.Calendar__day.-selected::after {
  content: attr(week);
  font-size: 10px;
}

.Calendar__monthYear.-shown {
  padding: 0 !important;
}

.Calendar__yearText {
  padding: 0 !important;
  font-weight: bold;
}

.Calendar__day.-selected {
  display: flex;
  flex-direction: column;
  color: black !important;
}
.calendar_right {
  margin-left: auto;
  display: flex;
  align-items: center;
}
.calendar_header {
  display: flex;
  align-items: center;
}
.calendar_container {
  padding: 10px;
}
.calendar_title {
  font-size: 20px;
  font-weight: 400;
}
.sync_btn{
  font-size: 18px;
  font-weight: 400;
  background-color: var(--primarycolor);
  color: var(--secondarycolor);
  border-radius: 5px;
  padding: 1px;
  margin-left: 10px;
  cursor: pointer;
}
.add_event {
  background-color: var(--primarycolor);
  border: none;
  font-size: 25px;
  margin: 10px;
  font-weight: 100;
  border-radius: 3px;
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  color: var(--secondarycolor);

}

.events_container {
  width: 350px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 25px;
  background-color: white;

  font-size: 15px;
  box-shadow: 0 1em 4em rgb(0 0 0 / 7%);
  box-sizing: border-box;
}
.events_today_list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.events_today_ {
  font-size: 17px;
  font-weight: 600;
}
.events_today_arrow {
  margin-left: auto;
}
.divider {
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.upcoming_event_right button {
  border: none;
  background-color: var(--primarycolor);
  margin: 2px;
  font-size: 12px;
  font-weight: 100;
  border-radius: 3px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.upcoming_event_right {
  display: flex;
}

.events_more {
  cursor: pointer;
}

.tooltip {
  position: relative;
  display: inline-block;
  border-bottom: 1px dotted black;
}

.tooltip .tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 3px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  bottom: 100%;
  left: 50%;
  font-size: 12px;
  margin-left: -80px;
}

.tooltip:hover .tooltiptext {
  visibility: visible;
}

@media screen and (max-width: 1550px) {
  .sidebar {
    width: 260px;

  }
  .mycal{
      max-width: 330px;
    }
    .events_container{
        max-width: 330px;
    }
    .rightContainer {
      width: 350px;
    }
    .home__main{
        margin-left:280px;
    }
}
  
  @media screen and (max-width: 1498px) {
    .sidebar {
      width: 240px;
    }
    .events_container{
        max-width: 310px;
      }
      .mycal{
        max-width: 310px;
        font-size: 9px;
      }
      .rightContainer {
        width: 330px;
      }
      .home__main{
          margin-left:220px;
      }
  }
  .custom-calendar {
    background-color: white;
  }
  @media screen and (max-width: 1460px) {
    .sidebar {
      width: 220px;
    }
    .events_container{
        max-width: 290px;
      }
      .mycal{
        max-width: 290px;
        font-size: 8.5px;
      }
      .rightContainer {
        width: 310px;
      }
      .home__main{
          margin-left:220px ;
      }
       
  }
  
  @media screen and (max-width: 1415px) {
    .sidebar {
      width: 200px;
    }
    .events_container{
        max-width: 290px;
      }
      .mycal{
        max-width: 290px;
        font-size: 8.5px;
      }
      .rightContainer {
        width: 310px;
      }
      .home__main{
          margin-left:200px ;
      }
      .drawer__container{
          width: 150px;
      } 
  }
   
@media screen and (max-width: 1550px) {
  .tooltip {
    margin-right: 70px !important;
  }   
}
 