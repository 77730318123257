.arrowclass{
    visibility: hidden !important;
}
 #bodystatarrow span.Dropdown-arrow{
    position: absolute;
 top: 10px;
}

@media screen and (max-width: 963px) {
    #printid{
        
        width: 94vw !important;
      position: relative;
      margin-right: 20px;
      margin-left: 20px !important;
      margin-top: 30px;
    }}
.chart_container {
    background-color: #f2f2f2;
    margin-top: 20px;
    width: 420px;
    font-family: sans-serif;
    /* text-align: center; */
    display: flex;
}

.reports__container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
}
.menu .item {
    width: 100px;
}
.chart_legend {
    color: rgb(128, 128, 128);
    display: flex;
    font-size: 20px;
    justify-content: space-around;
    align-items: center;
    height: 20px;
}

.chart_container .chart_ {
    background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    box-shadow: 0px 0px 1px 0px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
}

.chart_header {
    display: flex;
    align-items: center;
    margin-top: 15px;

    box-sizing: border-box;
}

.left_arrow:hover,
.right_arrow:hover {
    cursor: pointer;
}

.divider.text {
    margin-right: 50px !important;
    font-weight: 100 !important;
    font-size: 17px;
}

.left_arrow,
.right_arrow {
    width: 9px;
    height: 15px;
}

.dropdown_charts {
    border: none;
}
.dropdown_charts .Dropdown-control {
    border: none;
    width: 150px !important;
}
.dropdown_charts:hover {
    border-bottom: none !important;
}
.Dropdown-control {
    border-bottom: none !important;
}
.Dropdown-menu {
    width: 150px !important;
}

div.dropdown_ {
    display: flex;
    justify-content: center;
    text-align: left !important;
}

.date_range_input {
    width: 200px;
}

.react-datepicker__input-container input {
    border: none;
    outline: none;
    border-bottom: 1px solid black;
    margin: 5px;
    width: 100px;
    text-align: center;
}
.react-datepicker-ignore-onclickoutside {
    border: none;
    outline: none;
}
