#FormContainer {
  padding: 20px;
  padding-top: 0%;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  background-color: rgb(245, 245, 245);
}

input[type="file"] {
  display: none;
}
.custom-file-upload {
  border: 1px solid #ccc;
  display: inline-block;
  padding: 6px 12px;
  cursor: pointer;
}
select {
  width: 100%;
}
input#sport {
  background-color: rgb(255, 255, 255);
  color: black;
  font-weight: 500;
}

input {
  border-radius: 5px;
  padding: 5px 10px;
  margin-top: 10px;
}
@media screen and (max-width: 963px) {
  #physiotherapyform {
    width: 99vw !important;
    padding-left: 1% !important;
    margin-top: 50px !important;
  }
}
@media (max-width: 2000px) and (min-width: 1135px) {
  #physiotherapyform {
    margin-right: 10px !important;
    margin-left: 20px !important;
  }
}
@media (max-width: 1400px) and (min-width: 1300px) {
  #physiotherapyform {
    width: 53vw !important;
  }
}
@media (max-width: 1300px) and (min-width: 1200px) {
  #physiotherapyform {
    width: 56vw !important;
  }
}
