@media screen and (max-width: 963px) {
  .athleteNutrition {
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    z-index: 1;
    margin-top: 20px;
  }
}

.athleteNutrition__homeContainer {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.athleteNutrition__homeLeftContainer,
.athleteNutrition__homeRightContainer {
  flex: 0.5;
  padding: 10px;
}

.athleteNutrition__homeRightContainer {
  padding-left: 30px;
}

.athleteNutritionHeading__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}

.athleteNutritionHeading__row > div {
  cursor: pointer;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
}
.athleteNutritionHeading__row > div:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.athleteNutritionHeading__row > h1,
.athleteNutritionHeading__row > h1,
.athleteNutrition__homeRightContainer > h1 {
  font-size: 16px;
  font-weight: 500;
}

.assignByCoach__Card {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 90%;
  height: 60px;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  margin: 10px 0;
}

.assignByCoach__Card > img {
  object-fit: contain;
}

.assignByCoach__CardInfo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: 20px;
}

.assignByCoach__CardInfo > h4 {
  font-size: 12px;
  font-weight: 600;
}

.assignByCoach__CardInfoDates > h4 {
  font-size: 10px;
  font-weight: 600;
}

.rightArrow {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  object-fit: cover;
}
.athleteAddMeal {
  min-height: 100vh;
}

.athleteAddMealfood__container {
  margin: 20px;
  border-bottom: 1px solid var(--bordercolor);
}

.athleteAddMealfood__searchableDropdown {
  margin-top: 10px;
}

.athleteFoodCard {
  width: 100%;
}

.athleteFoodCard__openContainer {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding-right: 20px;
}

.athleteFoodCard__openContainer > h4 {
  margin: 10px 0;
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.quantity-servings__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 15px;
}

.quantity-servings__container > div > h4 {
  margin: 10px 0;
  font-size: 14px;
  font-weight: 600;
}

.foodCard__quantInput {
  border: 1px solid grey;
  border-radius: 5px;
  padding: 9px 10px;
  width: 50%;
}

.foodCard__servingsInputContainer {
  align-items: flex-start;
  /* margin-left: 1%;
  margin-top: 15px; */
}

.foodCard__macroNutrients > h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.foodCard__macroNutrientsContainer {
  display: flex;
  align-items: center;
}

.foodCard__macroNutrient {
  display: flex;
  align-items: center;
  margin-right: 5%;
}

.foodCard__macroNutrient > h4 {
  margin-right: 5px;
  width: 70px;
  font-size: 14px;
  font-weight: 600;
}

.foodCard__macroNutrient > h5 {
  font-weight: 400;
}

.foodCard__macroNutrientsInput {
  border: 1px solid #777;
  width: 80px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  margin-right: 5px;
}

.foodCard__caloriesContainer {
  display: flex;
  align-items: center;
}

.foodCard__caloriesContainer > h4 {
  width: 100px;
  margin-right: 10px;
  font-size: 14px;
  font-weight: 600;
}

.foodCard__caloriesContainer > h5 {
  font-weight: 400;
}

.foodCard__caloriesInput {
  border: 1px solid #777;
  width: 120px;
  height: 30px;
  text-align: center;
  border-radius: 4px;
  margin-right: 5px;
}

.foodCard__check {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 5px 7px;
  padding-top: 0;
  border-radius: 5px;
}

.foodCard__checkAlternative {
  padding: 10px;
  border: 0.7px solid grey;
  border-radius: 5px;
}

.foodCard__checkAlternative > div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.foodCard__addButtons {
  display: flex;
  align-items: center;
  margin: 0;
}

.foodCard__addfoodButton,
.foodCard__addmealButton {
  height: 40px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primarycolor);
  border-radius: 8px;
  margin-right: 15px;
  cursor: pointer;
}

.foodCard__addfoodButton > h3,
.foodCard__addmealButton > h3,
.athleteFoodCard__submitMealButton > h3 {
  font-size: 14px;
  font-weight: 600;
}

.athleteFoodCard__submitMealButton {
  height: 40px;
  width: 270px;
  margin-top: 20px;

  margin-left: 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primarycolor);
  border-radius: 8px;
  cursor: pointer;
}

.assignByCoach__Card:hover {
  cursor: pointer;
}

.weekGoalMain {
  display: flex;
  background-color: #fff;
  width: 85%;
  border-radius: 10px;
  margin-top: 18px;
  padding: 10px;
}

.weekGoalContainer {
}

.MuiSwitch-track {
  background-color: var(--primarycolor);
}
