.glow {
  
  
  text-align: center;
  animation-name: glow;
  animation-duration: 1.2s;
  animation-timing-function: ease-in-out;
  animation-delay: 1s;
  animation-iteration-count: infinite;
  animation-direction: alternate;
  
}

@keyframes glow {
  
  from {
    color: #ff2600;
    text-shadow: 0px 0px 1px #1e047c, 3px 3px 15px hsl(295, 92%, 66%);
    font-size: 15px;
  }
  
  to {
    color: rgb(0, 0, 0);
    font-size: 13px;
    text-shadow: 0px 0px 1px #df18f1, 0px 0px 6px #000000;
  }
}
.notification__container > h2 {
  font-size: 19px;
  font-weight: 600;
  line-height: 32px;
}
.notifications__body {
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1)
}

.arrow-right {
  width: 0;
  height: 0;
  margin-left: 6px;
  margin-top: 5px;
  margin-right: 10px;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transition: 0.5s all cubic-bezier(0.075, 0.82, 0.165, 1);
  border-left: 10px solid black;
}
.arrow-right:hover {
  cursor: pointer;
}
