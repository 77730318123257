.header {
  width: 100%;
}

.coachProfile__header {
  display: flex;
  align-items: center;
  background-color: var(--primarycolor);
  width: 100%;
}

.leftarrow {
  padding: 50px 15px;
}

.image {
  border-radius: 100px !important;
  background-color: grey;
  object-fit: cover;
  margin: 15px 30px;
}

.leftarrow:hover {
  cursor: pointer;
}
