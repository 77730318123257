input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.profile {
  width: 100%;
  min-height: 100vh;
}
.profile__container {
  width: 100%;
  height: 100%;
  flex: 0.65;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f3f3f3;
}

.profile__main {
  flex: 0.65;
  width: 100%;
  height: 100%;
}
.athleteAssessment {
  cursor: pointer;
}

.coachProfile__container {
  display: flex;
  align-items: center;
  height: 100%;
  width: 100%;
}

.coachProfile__leftContainer {
  flex: 1;
  border: none !important;
  display: flex;
  flex-direction: column;
  /* align-items: flex-start; */
  /* justify-content: center; */
  /* flex-wrap: wrap; */
  height: 100%;
  min-width: 95%;
}

.coachProfile__rightContainer {
  flex: 0.25;
  border: 1px solid #d3d3d3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.coachProfile__header {
  display: flex;
  align-items: center;
  background-color: var(--primarycolor);
  width: 100%;
}

.leftarrow {
  padding: 50px 15px;
}

.image {
  border-radius: 100px !important;
  background-color: grey;
  object-fit: cover;
  margin: 15px 30px;
}

.coachProfile__content > h1 {
  font-size: 27px;
  font-weight: 600;
  line-height: 32px;
}

.coachProfile__content > h3 {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
}

.coachProfile__info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.coachProfile__heading > h2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
  padding-right: 590px;
}

.coachProfile__editButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 20px;
  background-color: var(--primarycolor);
  height: 30px;
  width: 150px;
  margin: 10px 0;
  border-radius: 18px;
  font-size: 14px;
}

.coachProfileForm {
  width: 97%;
}

.coachProfileForm > form > h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
}

.coachProfileForm > form > input {
  padding: 10px;
  display: flex;
  width: 100%;
  border: none;
  outline: gray;
  background-color: white;
  box-sizing: border-box;
}

.coachProfileForm > form > textarea,
.athleteMedicalAssessment__form > textarea,
.athleteFoodAndLifestyleAssessment__textarea {
  background-color: white;
  padding: 10px;
  display: flex;
  width: 100%;
  border: none;
  outline: gray;
  height: 80px;
  width: 97%;
}

.coachProfileForm__Button,
.editProfileButton,
.saveProfileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 20px;
  background-color: var(--primarycolor) !important;
  color: var(--secondarycolor) !important;
  height: 30px;
  width: 150px;
  border-radius: 18px;
  font-size: 14px;
  margin-right: 80px !important;
  position: relative;
}

.editProfileButton:hover,
.saveProfileButton:hover,
.coachProfileForm__Button:hover,
.athleteMeasurements__Button:hover,
.editMeasurementFormButton:hover {
  cursor: pointer;
}

.coachAssessment > h2,
.coachMedicalAssessment > h2,
.athleteProfile__leftContainer > h2,
.athleteTrainingAssessment > h2,
.athleteFoodAndLifestyleAssessment__container > h2 {
  font-size: 21px;
  font-weight: 600;
  line-height: 32px;
}

.athleteMeasurements {
  width: 97%;
}

.athleteMeasurements__container > form > h4,
.athleteFoodAndLifestyleAssessment__container > h4,
.athleteFoodAndLifestyleAssessment__smokeContent > h4,
.athleteFoodAndLifestyleAssessment__alcoholContent > h4,
.athleteFoodAndLifestyleAssessment__eatingContent > h4,
.athleteFoodAndLifestyleAssessment__foodContent > h4 {
  font-size: 15px;
  font-weight: 400;
  line-height: 30px;
}

.athleteMeasurements__container > form > input,
.athleteFoodAndLifestyleAssessment__foodContent > input {
  background-color: white;
  padding: 10px;
  display: flex;
  width: 100%;
  border: none;
  outline: gray;
}

.athleteMeasurements__container > form > h6 {
  color: gray;
}

.athleteMeasurements__Button,
.athleteMedicalAssessment__Button,
.athleteFoodAndLifestyleAssessment__completeForm {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 14px;
  background-color: var(--primarycolor);
  height: 30px;
  width: 150px;
  margin: 10px 0;
  border-radius: 15px;
  font-size: 14px;
}

.athleteProfile__container {
  align-items: flex-start;
  height: 100%;
  width: 100%;
}

.athleteProfile__leftContainer {
  flex: 0.65;
  border: 1px solid #d3d3d3;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;

  padding: 0 20px;
}

.athleteProfile__rightContainer {
  flex: 0.35;
  border: 1px solid #d3d3d3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.athleteAssessment:hover {
  cursor: pointer;
}

.athleteAssessment {
  width: 98vw;
}

.athleteAssessments {
  width: 98vw;
}

.athleteAssessment__features {
  padding: 10px;
  display: flex;
  width: 100vw;
  border: none;
  outline: gray;
  background-color: white;
  margin-bottom: 13px;
  border-radius: 15px;
  justify-content: space-between;
}

.athleteAssessment__features > img {
  height: 24px;
  width: 24px;
}

.athleteAssessment__button {
  font-size: 14px;
}

.editProfileButton > h3,
.saveProfileButton > h3,
.athleteTrainingAssessment__editProfileButton > h3,
.athleteTrainingAssessment__saveProfileButton > h3 {
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 8px;
}

.viewReport {
  padding: 10px;
  display: flex;
  width: 50vw !important;
  border: none;
  outline: gray;
  background-color: #4b4b4b;
  margin-bottom: 13px;
  border-radius: 15px;
  justify-content: space-between;
  color: white;
  align-items: center;
}

.viewReport__button {
  font-size: 14px;
  margin-left: 20px;
  cursor: pointer;
}

.viewReport > img {
  height: 12px;
  margin-right: 5px;
}

.athleteMedicalAssessment__form > p,
.athleteMedicalAssessment__form > h4 {
  font-size: 15px;
  font-weight: 500;
  color: gray;
}

.MuiRadio-root > label {
  width: 40px;
  height: 40px;
  font-size: 13px;
}

.athleteMedicalAssessment__checkbox1 {
  display: flex;
  padding-bottom: 20px;
}

.athleteMedicalAssessment__checkbox2 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
  padding-bottom: 20px;
}

.athleteTrainingAssessment > h2 {
  padding-left: 20px;
}

.athleteTrainingAssessment > h4 {
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 20px;
}

.athleteTrainingAssessment > h5 {
  font-size: 14px;
  font-weight: 500;
  line-height: 30px;
  padding-left: 20px;
}

.athleteTrainingAssessment__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.athleteFoodAndLifestyleSelectdays__container {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-top: 20px;
}

.athleteTrainingAssessment__checkBox {
  display: flex;
  flex-wrap: wrap;
}

.athleteTrainingAssessment__Button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 14px;
  background-color: var(--primarycolor);
  height: 30px;
  width: 40%;
  margin: 10px 0;
  border-radius: 15px;
  font-size: 14px;
  margin-left: 15%;
  margin-top: 5%;
}

.smokeContentFrequency,
.alcoholConsumptionFrequency,
.eatingFrequency {
  display: flex;
  align-items: center;
  justify-content: center;
}

.athleteFoodAndLifestyleAssessment__completeForm {
  margin-top: 50px;
}

.athleteTrainingAssessment__editProfileButton,
.athleteTrainingAssessment__saveProfileButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 20px;
  background-color: var(--primarycolor);
  height: 30px;
  width: 150px;
  margin: 10px 0;
  border-radius: 18px;
  font-size: 14px;
  margin-top: 50px;
  cursor: pointer;
}

.athlete__item:hover {
  cursor: pointer;
}
#profilehead {
  width: 200px !important;
}
.react-datepicker__input-container input {
  padding: "5px 10px";
}
.coachprofileform {
  margin-left: 13px !important;
}
.coachProfile {
  margin-right: 10%;
}
@media screen and (max-width: 2135px) {
  #anthroinput,
  form#antroform {
    width: 53vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 59vw !important;
  }
}
@media screen and (max-width: 1790px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 56vw !important;
  }
}
@media screen and (max-width: 1655px) {
  #anthroinput,
  form#antroform {
    width: 51vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 57vw !important;
  }
}
@media screen and (max-width: 1575px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 56vw !important;
  }
}
@media screen and (max-width: 1555px) {
  #anthroinput,
  form#antroform {
    width: 53vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 59vw !important;
  }
}

@media screen and (max-width: 1485px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 63vw !important;
  }
}

@media screen and (max-width: 1455px) {
  #anthroinput,
  form#antroform {
    width: 52vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 61vw !important;
  }
}
.athleteMedicalAssessment__container {
  width: 100% !important;
}

@media screen and (max-width: 1485px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 60vw !important;
  }
}
@media screen and (max-width: 1418px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 55vw !important;
    position: relative;
    left: 10px;
  }
}

@media screen and (max-width: 1355px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 55vw !important;
  }
}

@media screen and (max-width: 1345px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 57vw !important;
  }
}
@media screen and (max-width: 1322px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 57vw !important;
  }
}

@media screen and (max-width: 1307px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 58vw !important;
  }
}
@media screen and (max-width: 1255px) {
  .coachProfile__leftContainer {
    width: 55vw !important;
  }
}
@media screen and (max-width: 1135px) {
  #anthroinput {
    width: 62vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 68vw !important;
  }
  div#profile {
    width: 100vw;
    position: absolute;
    left: -39px;
  }
}
@media screen and (max-width: 1073px) {
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 68vw !important;
  }
}
@media screen and (max-width: 963px) {
  .coachProfile {
    margin-right: 0% !important;
    margin-top: 50px;
  }
  #athleteAssessments {
    margin-left: 20px !important;
  }
  #medicaltwo {
    width: 100vw !important;
    padding: 0% !important;
    margin: 0% !important;
  }
  .athleteMeasurements,
  .AthleteMedicalAssessment {
    margin-top: 50px !important;
  }
  .athleteMedicalAssessment__container {
    padding-left: 1% !important;
  }
  .profile {
    margin-left: 0% !important;
  }
  .athleteProfile__leftContainer {
    width: 100vw !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    position: relative;
    border: none !important;
    margin-right: 0%;
    margin-left: 0%;
    margin-top: 50px;
  }
  .athleteMeasurements__container {
    margin-left: 20px;
  }
  #anthroinput,
  form#antroform {
    width: 90vw !important;
  }
  div.coachProfile > div.coachProfile__container > div#athleteprofileform {
    min-width: 88vw !important;
    margin-left: 0px !important;
    padding: 0px !important;
  }
  div#profile {
    width: 100vw;
    position: absolute;
    left: -66px;
  }
}
.athleteProfile__leftContainer {
  border: none !important;
}

@media (max-width: 942px) and (min-width: 900px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 1% !important;
  }
}

@media screen and (max-width: 900px) and (min-width: 880px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 01% !important;
  }
}
@media screen and (max-width: 880px) and (min-width: 850px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 01% !important;
  }
}
@media screen and (max-width: 850px) and (min-width: 820px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 1% !important;
  }
}
@media screen and (max-width: 820px) and (min-width: 800px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 1% !important;
  }
}
@media screen and (max-width: 800px) and (min-width: 782px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 1% !important;
  }
}
@media screen and (max-width: 782px) {
  .coachProfile {
    margin-right: 0% !important;
  }
  .profile {
    margin-left: 1% !important;
  }
  .editProfileButton {
    margin-left: -400px !important;
  }
}

@media screen and (max-width: 3322px) {
  .athleteAssessment,
  .viewReport {
    width: 55vw !important;
  }
}
@media screen and (max-width: 1112px) {
  .athleteAssessment,
  .viewReport {
    width: 63vw !important;
  }
}
@media screen and (max-width: 963px) {
  .athleteAssessment,
  .viewReport {
    width: 85vw !important;
  }
  .athleteTrainingAssessment {
    width: 100vw !important;
    margin-top: 50px !important;
  }
}

@media (max-width: 1350px) and (min-width: 1300px) {
  .athleteTrainingAssessment {
    width: 57vw !important;
  }
}
@media (max-width: 1500px) and (min-width: 1350px) {
  .athleteTrainingAssessment {
    width: 56vw !important;
  }
}

@media (max-width: 2000px) and (min-width: 1650px) {
  .athleteTrainingAssessment {
    width: 56vw !important;
    position: relative !important;
    left: 20px !important;
  }
}

@media (max-width: 1555px) and (min-width: 1522px) {
  .athleteTrainingAssessment {
    position: relative !important;
    left: 20px !important;
  }
}
@media (max-width: 1522px) and (min-width: 1500px) {
  .athleteTrainingAssessment {
    position: relative !important;
    left: 0px !important;
  }
}
@media (max-width: 1485px) and (min-width: 1400px) {
  .athleteTrainingAssessment {
    position: relative !important;
    width: 59vw !important;
    left: 20px !important;
  }
}

@media (max-width: 1418px) and (min-width: 1400px) {
  .athleteTrainingAssessment {
    position: relative !important;
    width: 56vw !important;
    left: 20px !important;
  }
}

@media (max-width: 1400px) and (min-width: 1350px) {
  .athleteTrainingAssessment {
    position: relative !important;
    width: 54vw !important;
    left: 20px !important;
  }
}
@media (max-width: 1350px) and (min-width: 1135px) {
  .athleteTrainingAssessment {
    position: relative !important;
    width: 56vw !important;
    left: 20px !important;
  }
}
@media screen and (max-width: 782px) {
  .editProfileButton {
    margin-left: 10px !important;
  }
  .athleteAssessment,
  .viewReport {
    width: 85vw !important;
  }
}
