.rightContainer {
  position: fixed;
  right: 0px;
  width: min-content;
  height: 100%;
  top: 0px;
  overflow-y: auto;
  border: 1px solid rgb(216, 216, 216);
}

.rightContainer::-webkit-scrollbar {
  display: none;
}

