.workouts__home,#assignworkout,.coachCreateWorkout,#savedworkouts,#assignworkouts{
margin-top: 20px; 
}
div.viewallsavedworkouts{
  margin-top: 20px;
}
@media screen and (max-width: 2000px) {
div.eachWeek{
  width: 58vw !important;
}
img#rightarrow{
  position: relative;
  right: 0px !important;
}
img#leftarrow{
  position: relative;
  left: 17px !important;
}
div.leftweek{
  position: relative;
  left: 20px !important;
}
.longtermworkoutbackground{
  min-width: 480px !important;
}
}
@media screen and (max-width: 1815px) {
  .longtermworkoutbackground{
    min-width: 420px !important;
  }
  div.eachWeek{
    position: relative;
    left: 20px;
  }
  img.rightarrow{
    position: relative;
    right: 30px;
  }
}
@media screen and (max-width: 1782px) {
  div.eachWeek{
    min-width: 55vw !important;
  }
  
img.rightarrow{
  position: relative;
  right: 6px !important;
}
  }
  @media screen and (max-width: 1722px) {
    .longtermworkoutbackground{
      min-width: 390px !important;
    }
    
  img.rightarrow{
    position: relative;
    right: 6px !important;
  }
    }
  @media screen and (max-width: 1690px) {
    
    div.longtermworkoutbackground{
      width: 320px !important;
    }
    img#rightarrow{
      position: relative;
      right: 10px !important;
    }
    img#leftarrow{
      position: relative;
      left: 17px !important;
    }
    div.leftweek{
      position: relative;
      left: 20px !important;
    }
  }
    
  @media screen and (max-width: 1649px) {   
    div.eachWeek{
      position: relative;
      left: 0px !important;
    }
    img.rightarrow{
      left: 11px !important;
    }
    
     }
  @media screen and (max-width: 1610px) {    
    div.eachWeek{
      position: relative;
      left: 0px !important;
    }
    img.rightarrow{
      left: 1px !important;
    }
    div.longtermworkoutbackground{
      min-width: 353px !important;
    }
  }
@media screen and (max-width: 1555px) {
  div.eachWeek{
    position: relative;
    left: 15px !important;
  }
  img.rightarrow{
    left: -25px !important;
  }
  div.longtermworkoutbackground{
    min-width: 363px !important;
  }
  }
  
@media screen and (max-width: 1522px) {
  div.eachWeek{
    position: relative;
    left: 0px !important;
  }
  img.rightarrow{
    left: -25px !important;
  }
  div.longtermworkoutbackground{
    min-width: 363px !important;
  }
  }
  
@media screen and (max-width: 1485px) {
  div.eachWeek{
    position: relative;
    left: 15px !important;
  }
  img.rightarrow{
    left: -14px !important;
  }
  div.longtermworkoutbackground{
    min-width: 363px !important;
  }
 
 .backdivwkt{
   width: 110% !important;
   padding-left: 22px !important;
   margin-left: -34px;
 }

 .backdivvod{
  width: 95% !important;
  padding-left: 22px !important;
  margin-left: 33px;
}
.vodathletename{
  width: 250px !important;
}
  }

@media screen and (max-width: 1418px) {
  div.eachWeek{
    position: relative;
    left: 15px !important;
  }
  img.rightarrow{
    left: -14px !important;
  }
  div.longtermworkoutbackground{
    min-width: 323px !important;
  }
}

@media screen and (max-width: 1385px) {
  div.eachWeek{
    position: relative;
    left: 15px !important;
  }
  img.rightarrow{
    left: -10px !important;
  }
  div.longtermworkoutbackground{
    min-width: 323px !important;
  }
  .daywkt{
    font-size: 8px !important;
  }
  .datewkt{
    font-size: 8px !important;
  }
  .rightbtnwkt{
    position: relative;
    left: -3px;
  }
 .leftbtnwkt{
   position: relative;
   left: 1px;
 }
 .backdivwkt{
   width: 118% !important;
   padding-left: 22px !important;
   margin-left: -34px;
 }
  .backdivvod{
   width: 103% !important;
   padding-left: 12px !important;
   margin-left: -15px;
 }
 .vodathletename{
   width: 250px !important;
 }
}

@media screen and (max-width: 1348px) {
  div.eachWeek{
    position: relative;
    left: 15px !important;
  }
  img.rightarrow{
    left: 10px !important;
  }
  div.longtermworkoutbackground{
    min-width: 313px !important;
  }
}
  @media screen and (max-width: 1300px) {
    div.eachWeek{
      position: relative;
      left: 25px !important;
    }
    img.rightarrow{
      left: -10px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 308px !important;
    }
  }
  @media screen and (max-width: 1254px) {
    div.eachWeek{
      position: relative;
      left: 25px !important;
    }
    img.rightarrow{
      left: -5px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 288px !important;
    }
  }
  @media screen and (max-width: 1200px) {
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: 15px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 268px !important;
    }
    .insidescrollwkt{
      width: 35px !important;
    }
  }
  @media screen and (max-width: 1135px) {
    .insidescrollwkt{
      width: 40px !important;
    }
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: -5px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 318px !important;
    }
  }
  @media screen and (max-width: 1050px) {
    .insidescrollwkt{
      width: 35px !important;
    }
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: -5px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 298px !important;
    }
  }
  @media screen and (max-width: 1000px) {
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: -10px !important;
    }
    div.longtermworkoutbackground{
      min-width: 213px !important;
      max-width: 278px !important;
    }
  }
  @media screen and (max-width: 963px) {
    
  .daywkt{
    font-size: 8px !important;
  }
  .datewkt{
    font-size: 8px !important;
  }
  .rightbtnwkt{
    position: relative;
    left: -3px;
  }
 .leftbtnwkt{
   position: relative;
   left: 1px;
 }
 .backdivwkt{
   width: 110% !important;
   padding-left: 22px !important;
   margin-left: -34px;
 }
 .backdivvod{
  width: 100% !important;
  padding-left: 22px !important;
  margin-left: -34px;
}
 .insidescrollwkt{
  width: 40px !important;
}
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: -10px !important;
    }
    div.longtermworkoutbackground{
      min-width: 51vw !important;
      max-width: 52vw !important;
    }
  }
  
  @media screen and (max-width: 822px) {
    div.eachWeek{
      position: relative;
      left: 15px !important;
    }
    img.rightarrow{
      left: -10px !important;
    }
    .backdivwkt{
      width: 110% !important;
      padding-left: 22px !important;
      margin-left: -34px;
    }
    
    .backdivvod{
      width: 100% !important;
      padding-left: 22px !important;
      margin-left: -34px;
    }
    .insidescrollwkt{
     width: 35px !important;
   }
    div.longtermworkoutbackground{
      min-width: 244px !important;
      max-width: 35vw !important;
    }
  }
@media screen and (min-width: 1400px) {
  .workouts__home{
    width: 58vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1419px) {
  .workouts__home{
    width: 62vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1485px) {
  .workouts__home{
    width: 59vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1500px) {
  .workouts__home{
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1550px) {
  .workouts__home{
    width: 58vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}

@media screen and (min-width: 1556px) {
  .workouts__home{
    width: 55vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1600px) {
  .workouts__home{
    width: 57vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (min-width: 1766px) {
  .workouts__home{
    width: 59vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }  
}

@media screen and (min-width: 1800px) {
  .workouts__home{
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }  
  }

@media screen and (max-width: 963px) {
  .workouts__home, .coachCreateWorkout,#savedworkouts,#assignworkouts, #assignworkoutlongtermworkout{
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 50px !important;
  }  
  #inputtag{
    width: 92vw !important;
  }
  
  #inputtagg{
    width: 92vw !important;
  }
}
@media screen and (max-width: 963px) {
  .workouts__home, .coachCreateWorkout,#savedworkouts,#assignworkouts{
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }
}
.workoutsHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.workoutsHeader__info {
  display: flex;
  align-items: center;
}

.workoutsHeader__info > h1 {
  font-size: 24px;
  font-weight: 600;
}

.addWorkout__button {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: var(--primarycolor);
  color: var(--secondarycolor);
  border-radius: 20px;
  height: 20px;
  padding: 5px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.addWorkout__button > img {
  margin-right: 8px;
}

.addWorkout__button > h5 {
  font-size: 12px;
  font-weight: 600;
}

.workoutsHeader__backButton > img {
  object-fit: contain;
  margin: 0 20px;
  cursor: pointer;
}

.workouts__homeContainer {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.workouts__homeLeftContainer,
.workouts__homeRightContainer {
  flex: 0.4;
  padding: 10px;
}

.workoutHeading__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}

.workoutHeading__row > div {
  cursor: pointer;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
}
.workoutHeading__row > div:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.workoutHeading__row > h1,
.workoutHeading__row > h1,
.workouts__homeRightContainer > h1 {
  font-size: 16px;
  font-weight: 500;
}

.workoutRecord {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  border-radius: 5px;
  position: relative;
  height: 80px;
  margin: 10px 0;
}

.workoutRecord__info {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-right: 5px;
  border-right: 1px solid #d3d3d3;
  width: 33.33%;
  height: 60%;
  position: relative;
}

.workoutRecord__info:nth-child(3) {
  border-right: 0px solid #d3d3d3;
}

.workoutRecord__info > h5 {
  font-size: 12px;
  font-weight: 800;
  margin: 5px;
  text-align: center;
}
.workoutRecord__info > h6 {
  font-size: 12px;
  font-weight: 400;
  margin: 5px;
  text-align: center;
}

.coachWorkouts__header {
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.coachWorkouts__header > img {
  height: 20px;
  width: 20px;
  background-color: gray;
}

.coachWorkouts__header > h2 {
  font-size: 26px;
  font-weight: 600;
  line-height: 30px;
}

.coachWorkouts__createButton {
  display: flex;
  align-items: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: var(--primarycolor);
  border-radius: 20px;
  height: 20px;
  padding: 5px 20px;
  font-weight: 500;
}

.coachWorkouts__createButton > img {
  height: 14px;
  width: 15px;
  padding-right: 7px;
}

.coachWorkouts__main {
  display: flex;
  flex: 1;
}

.coachWorkouts__savedWorkouts {
  flex: 0.5;
}

.coachWorkouts__workoutLibrary {
  flex: 0.5;
}

.coachCreateWorkout > img {
  margin-left: 5%;
  margin-top: 1.9%;
}

.coachCreateWorkout__workoutName {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  padding-left: 5%;
}

.coachCreateWorkout__workoutName > input {
  padding: 10px;
  display: flex;
  width: 45%;
  outline: gray;
  background-color: white;
  border: none;
}

.createWorkout__banner {
  background-color: white;
  margin: 20px 4.5%;
}

.createWorkout__banner > img {
  object-fit: contain;
}

.createWorkout__subHeading {
  padding-left: 5%;
  font: size 15px;
  font-weight: 700;
}

.selectDropdown1 {
  display: flex;
  flex-direction: column;
}

/* .createWorkout__row {
  display: flex;
  align-items: center;
} */

.createWorkout__inputTime,
.createWorkout__calorieBurn,
.createWorkout__description,
.createWorkout__workoutDifficulty,
.createWorkout__exercises {
  padding-bottom: 2%;
}

.createWorkout__inputTime > h3,
.createWorkout__calorieBurn > h3,
.createWorkout__description > h3,
.createWorkout__workoutDifficulty > h3 {
  font-size: 18px;
  font-weight: 400;
  line-height: 20px;
}

.createWorkout__exercises > h3 {
  font-size: 18px;
  font-weight: 700;
  line-height: 20px;
}

.createWorkout__inputTime > input,
.createWorkout__calorieBurn > input {
  padding: 10px;
  display: flex;
  width: 39%;
  outline: gray;
  background-color: white;
  border: none;
}

.createWorkout__description > textarea {
  padding: 10px;
  display: flex;
  width: 49%;
  outline: gray;
  background-color: white;
  border: none;
  height: 70px;
}

.createWorkout__completeWorkoutButton {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: var(--primarycolor);
  border-radius: 20px;
  height: 30px;
  padding: 5px 20px;
  font-weight: 500;
  width: 25%;
  margin-left: 20px;
  margin-bottom: 5%;
  cursor: pointer;
}

.createWorkout__input {
  border-radius: 5px;
  outline-width: 0;
  padding: 15px 3px;
  width: 39%;
  outline: gray;
  background-color: white;
  border: 1px solid grey;
}

.createWorkout__inputLabel {
  font-size: 15px;
  font-weight: 500;
}

.createWorkout__modal {
  display: flex;
  flex-direction: column;

  justify-content: center;
  margin: 30px;
}

.createWorkout__modal > h3 {
  font-size: 15px;
  font-weight: 700;
  height: 5px;
}
.createWorkout__modal > h4 {
  font-size: 14px;
  font-weight: 500;
  height: 5px;
}

.createWorkout__modalButtons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 50px;
  margin-bottom: 20px;
}

.createWorkout__modalButton {
  display: flex;
  align-items: center;
  margin-right: 20px;
  background-color: var(--primarycolor);
  border-radius: 20px;
  height: 30px;

  font-weight: 500;
  cursor: pointer;
}

/* Assign Workout */

.assignWorkout {
  padding: 10px;
}

.assignWorkout > h3 {
  font-size: 16px;
  font-weight: 700;
}

.assignWorkout__summaryCard {
  padding: 10px;
  padding-top: 3px;
  display: flex;
  align-items: center;
  background-color: white;
  border-radius: 15px;
  flex: 0.5;
  margin-bottom: 15px;
}

.assignWorkout__summaryCardLeft,
.assignWorkout__summaryCardRight {
  flex: 0.5;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.assignWorkout__summaryCardLeftComponents {
  display: flex;
  align-items: center;
  margin: 8px 0;
}

.assignWorkout__summaryCardLeftComponents > input {
  width: 70px;
  margin: 0 7px;
}

.assignWorkout__summaryCardRow {
  display: flex;
  align-items: center;
  margin: 0;
}

.assignWorkout__summaryCardRow > h5 {
  font-size: 14px;
  font-weight: 700;
  margin-right: 5px;
}
.assignWorkout__summaryCardRow > h6 {
  font-size: 12px;
  font-weight: 600;
}

.assignWorkout__banner {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  background-color: var(--primarycolor);
  border-bottom-left-radius: 18px;
  border-bottom-right-radius: 18px;
}

.assignWorkout__banner > div {
  margin-left: 10px;
}

.assignWorkout__banner > div > h5 {
  font-size: 14px;
  font-weight: 600;
  height: 0px;
  line-height: 1px;
}
/* .assignWorkout__banner > div > h6 {
  font-size: 13px;
  font-weight: 500;
  height: 0px;
  line-height: 1px;
} */

.iYKfNu {
  width: 100% !important;
}

.filter div:hover {
  cursor: pointer;
}
.filter_icon:hover {
  cursor: pointer;
}
