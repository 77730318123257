@media screen and (min-width:963px) {
.Postworkout__container{
  margin-top: 20px !important;
}
}
.Postworkout__container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 100%;
}
.Postworkout__body {
  box-sizing: border-box;
  padding: 30px;
  width: 100%;

  display: flex;
  flex-direction: column;
}
.datepicker{
  padding: 0px !important;
  float: left !important;
  display: flex !important;
  justify-content: start !important;
  position: relative;
  left: -12px;
  background-color: transparent !important;
}
.Datepicker__container {
  float: left !important;
  display: flex;
  border: 1px solid rgb(219, 226, 234) !important;
  height: 30px;
  border: 1px solid grey;
  background-color: White;
  padding: 0px;



  padding-left: 0px !important;
  border-radius: 10px;
}
.Planned_data {
  margin-top: 20px;
  display: flex;
  align-items: center;

  flex-direction: row;
}
.Planned_data > div {
  text-align: center;
  margin-left: 20px;
}

.react-datepicker__input-container input {
  border: none;
  background-color: white;
}

input {
  border: 1px solid black;
  box-shadow: none;
}

.Submit__button :hover {
  cursor: pointer;
}
