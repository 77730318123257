@media screen and (max-width: 1400px) {
  .variableclass {
    min-width: 640px !important;
    max-width: 708px !important;
    position: relative;
    margin-top: 20px;
    left: 160px;
  }
  .variableclasstwo {
    min-width: 640px !important;
    max-width: 708px !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 650px !important;
    max-width: 708px !important;
    position: relative;
    margin-top: 20px;
    left: 159px;
  }
}

@media screen and (max-width: 1200px) {
  .variableclass {
    min-width: 600px !important;
    max-width: 618px !important;
    position: relative;
    margin-top: 20px;
    left: 160px;
  }
  .variableclasstwo {
    min-width: 600px !important;
    max-width: 618px !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 610px !important;
    max-width: 618px !important;
    position: relative;
    margin-top: 20px;
    left: 149px;
  }
}
@media screen and (max-width: 1134px) {
  .variableclass {
    min-width: 670px !important;
    max-width: 678px !important;
    position: relative;
    margin-top: 20px;
    left: 180px;
  }
  .variableclasstwo {
    min-width: 670px !important;
    max-width: 678px !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 670px !important;
    max-width: 678px !important;
    position: relative;
    margin-top: 20px;
    left: 179px;
  }
}

@media screen and (max-width: 1074px) {
  .variableclass {
    min-width: 660px !important;
    max-width: 668px !important;
    position: relative;
    margin-top: 20px;
    left: 160px;
  }
  .variableclasstwo {
    min-width: 660px !important;
    max-width: 668px !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 660px !important;
    max-width: 668px !important;
    position: relative;
    margin-top: 20px;
    left: 149px;
  }
}

@media screen and (max-width: 1022px) {
  .variableclass {
    min-width: 600px !important;
    max-width: 608px !important;
    position: relative;
    margin-top: 10px !important;
    left: 160px;
  }
  .variableclasstwo {
    min-width: 600px !important;
    max-width: 608px !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 630px !important;
    max-width: 638px !important;
    position: relative;
    margin-top: 20px;
    left: 149px;
  }
}
@media screen and (max-width: 963px) {
  .extraclass {
    width: 90vw !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (max-width: 963px) {
  .variableclass {
    min-width: 90vw !important;
    max-width: 91vw !important;
    position: relative;
    margin-top: 20px;
    left: 160px;
  }
  .variableclasstwo {
    min-width: 90vw !important;
    max-width: 91vw !important;
    position: relative;
    margin-top: 20px;
  }

  .variableclassthree {
    min-width: 90vw !important;
    max-width: 91vw !important;
    position: relative;
    margin-top: 20px;
    left: 179px;
  }
}

.nutritionCard {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 90%;
  height: 60px;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  margin: 10px 0;
  cursor: pointer;
}

.nutritionCard_main {
  display: flex;
  align-items: center;
  width: inherit;
}

.nutritionCard__coach {
  margin-top: 10px;
  text-align: right;
  font-size: 12px;
}

.nutritionCard_main > img {
  object-fit: contain;
}

.nutritionCard__info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 20px;
}

.nutritionCard__info h1 {
  font-size: 16px;
  font-weight: 600;
}

.nutritionCard__macroNutrients {
  display: flex;
  align-items: center;
  height: 20px;
}

.nutritionCard__macroNutrients > h3 {
  width: 100px;
  font-size: 12px;
  font-weight: 500;
}

.right__arrow {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  object-fit: cover;
  position: absolute;
  right: 15px;
}
