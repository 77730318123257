.paper{
  min-height: 450px;
}
#bell,
#calendericon{
  display: none;
}
div.addWorkout__button{
  position: relative;
  right: 30px;
}
#menutoggle{
  display: none;
  position: absolute;
  left: 10px;
  top: 10px;
}
  .mycal, .custom-calendar{
    font-size: 10px;
  max-width: 760px;
  background-color:var(--primarycolor) ;
  }
  @media screen and (max-width:3000px) {
    
    #iframe{
      width: 500px !important;
    }
    div.coachProfile__container ,.profile{
      margin-left: 0% !important;
      width: 67vw !important;
    }
.workouts__home,#savedworkouts,#assignworkouts{
  width: 70vw;
 }
 #nutid{
   width: 68vw;
 }
  }  
  
  @media screen and (max-width:2597px) {
    div.coachProfile__container ,.profile{
      margin-left: 0% !important;
      width: 63vw !important;
    }
    .rightContainer {
      
      width: 510px !important;
    }  
.workouts__home, .coachCreateWorkout,#savedworkouts,#assignworkouts{
  width: 67vw;
 }
  }  
  
  @media screen and (max-width:2321px) {
    .rightContainer {
      width: 469px !important;
    }  
.workouts__home, .coachCreateWorkout,#savedworkouts,#assignworkouts{
  width: 67vw;
 }
  }  
  
  @media screen and (max-width:2291px) {
    .rightContainer {
      width: 419px !important;
    }  
.workouts__home,#savedworkouts,#assignworkouts{
  width: 67vw;
 }
  }  
  
  @media screen and (max-width:2031px) {
    .rightContainer {
      width: 419px !important;
    }  
    #homediv{
      width: 80vw !important;
    }
.workouts__home,#savedworkouts{
  width: 62vw;
 }
 #assignworkouts.assign{
  width:98%;
}
 #nutid{
   width:64vw;
 }
 .coachCreateWorkout{
  width: 59vw;
}
  }  
  
  @media screen and (max-width:1828px) {
      
    #homediv{
      width: 78vw !important;
    }
    div.coachProfile__container ,.profile{
      margin-left: 0% !important;
      width: 60vw !important;
    }
    
    .rightContainer {
      width: 349px !important;
    }     
 .coachCreateWorkout{
  width: 58vw !important;
}
.workouts__home,#savedworkouts,#assignworkouts{
  width: 60vw;
 }
 #newassessment{
  margin-left: 20% !important;
}
  }  
  
  @media screen and (max-width:1658px) { 
    #homediv{
      width: 74.7vw !important;
    }
    #nutid{
      width:60.5vw;
    }
    .rightContainer {
      width: 349px !important;
    }  
.workouts__home,#savedworkouts,#assignworkouts{
  width: 58.5vw;
 }
 .coachCreateWorkout{
  width: 56vw !important;
}
  }  
  @media screen and (max-width:1650px) {
    .coachCreateWorkout{
      width: 55vw !important;
    } 
  }
  @media screen and (max-width:1555px) {
    .coachCreateWorkout{
      width: 59vw !important;
    } 
  }
  @media screen and (max-width:1550px) {
    #homediv{
      width: 79.5vw !important;
    }
    #nutid{
      width:100%;
    }
.workouts__home,#savedworkouts{
  width: 105%;
 }
 #assignworkouts.assign{
   width:100%;
 }
 .coachCreateWorkout{
  width: 59vw !important;
} 
  }
@media screen and (max-width:1485px) {
  .coachCreateWorkout{
    width:62vw !important;
  } 
}
@media screen and (max-width:1450px) {
  .workouts__home{
    width: 100%;
   }
    } 
@media screen and (max-width:1418px) {
  .coachCreateWorkout{
    width:56vw !important;
  } 
  
 #assignworkouts.assign{
  width:95%;
}
}
@media screen and (max-width:1350px) {
  
 #assignworkouts.assign{
  width:98%;}
}

@media screen and (max-width:1307px) {
  .coachCreateWorkout{
    width: 60vw !important;;
  } 
}
@media screen and (max-width:1185px) {
  .coachCreateWorkout{
    width:59vw;
  } 
}
@media screen and (max-width:1135px) {
  .coachCreateWorkout{
    width: 72vw !important;
  } 
}
@media screen and (max-width:1077px) {
  .coachCreateWorkout{
    width: 71vw !important;
  } 
}
@media screen and (max-width:1000px) {
  .coachCreateWorkout{
    width: 70vw !important;
  } 
}
  @media screen and (max-width: 2000px) {
    div.Calendar__sectionWrapper{
      min-height: 285px !important;
    }
    .sidebar {
      width: 460px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 440px !important;
        min-width: 409px !important;
        font-size: 9.5px !important;
      }
      .events_container{
        min-width: 409px !important;
          max-width: 440px !important;
          
      }
      .rightContainer {
        width: 450px !important;
      }
      .home__main{
          margin-left:300px !important;
      }
      .chart_container{
        width: 587px !important;
      } 
      .chart_{
        height: 400px !important;
        width: 545px !important;
      }
      .home__reportsRightInnerContainer{
        height: 407px !important
    }
  }
  
  @media screen and (max-width: 1940px) {
    .sidebar {
      width: 460px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 440px !important;
        min-width: 409px !important;
        font-size: 9.5px !important;
      }
      .events_container{
        min-width: 409px !important;
          max-width: 440px !important;
          
      }
      .rightContainer {
        width: 450px !important;
      }
      .home__main{
          margin-left:300px !important;
      }
      
      .chart_container{
        width: 567px !important;
      } 
      .chart_{
        width: 531px !important;
      }
      .home__reportsRightInnerContainer{
        height: 401px !important
    }
    
div.addWorkout__button{
  position: relative;
  right: 40px;
}
  }
  @media screen and (max-width: 1900px) {
    .sidebar {
      width: 460px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 400px !important;
      }
      .events_container{
          max-width: 400px !important;
      }
      .rightContainer {
        width: 420px !important;
      }
      .home__main{
          margin-left:300px !important;
      }
      
      .chart_container{
        width: 560px !important;
      } 
      .chart_{
        width: 527px !important;
      }
  }
  
  @media screen and (max-width: 1862px) {
    .sidebar {
      width: 460px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 400px !important;
      }
      .events_container{
          max-width: 400px !important;
      }
      .rightContainer {
        width: 420px !important;
      }
      .home__main{
          margin-left:300px !important;
      }
      
      .chart_container{
        width: 530px !important;
      } 
      .chart_{
        width: 497px !important;
      }
  }
  
  @media screen and (max-width: 1800px) {
    .sidebar {
      width: 460px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 400px !important;
      }
      .events_container{
          max-width: 400px !important;
      }
      .rightContainer {
        width: 420px !important;
      }
      .home__main{
          margin-left:300px !important;
      }
      
      .chart_container{
        width: 507px !important;
      } 
      .chart_{
        width: 467px !important;

      }
      .home__reportsRightInnerContainer{
     
        height: 374px !important;
    }
  }
       
  @media screen and (max-width: 1734px) {
    .home__reportsRightInnerContainer{
      height: 365px !important;
  }
    .sidebar {
      width: 440px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 390px !important;
      }
      .events_container{
          max-width: 390px !important;
      }
      .rightContainer {
        width: 410px !important;
      }
      .home__main{
          margin-left:290px !important;
      }
      
      .chart_container{
        width: 487px !important;
      } 
      .chart_{
        width: 447px !important;
      }
      #athleteprofileform{
        position: relative;
        left: 30px;
      }
  }
  @media screen and (max-width: 1655px) {
    #athleteprofileform{
      position: relative;
      left: 15px;
    }
  }     
  @media screen and (max-width: 1685px) {
    .sidebar {
      width: 420px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 380px !important;
      }
      .events_container{
          max-width: 380px !important;
      }
      .rightContainer {
        width: 400px !important;
      }
      .home__main{
          margin-left:290px !important;
      }
      
      .chart_container{
        width: 477px !important;
      } 
      .chart_{
        width: 437px !important;
      }
  }
       
  @media screen and (max-width: 1650px) {
    #athleteprofileform{
      position: relative;
      left: 0px;
    }
    .sidebar {
      width: 400px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 330px !important;
        min-width: 329px !important;
      }
      .events_container{
        min-width: 329px !important;
          max-width: 330px !important;
      }
      .rightContainer {
        width: 350px !important;
      }
      .home__main{
          margin-left:320px !important;
      }
      
      .chart_container{
        width: 467px !important;
      } 
      .chart_{
        width: 430px !important;
      }
  }
     
  @media screen and (max-width: 1630px) {
    .sidebar {
      width: 400px;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 330px;
      }
      .events_container{
          max-width: 330px;
      }
      .rightContainer {
        width: 350px;
      }
      .home__main{
          margin-left:320px;
      }
      
      .chart_container{
        width: 437px !important;
      } 
      .chart_{
        height: 386px !important;
        width: 400px !important;
      }
      .home__reportsRightInnerContainer{
        
        height: 345px !important;
    }
  }
     
  @media screen and (max-width: 1562px) {
    .sidebar {
      width: 350px !important;
    }
    .signout__button {
      width: 300px !important;
    }
    .mycal, .custom-calendar{
        max-width: 330px;
      }
      .events_container{
          max-width: 330px;
      }
      .rightContainer {
        width: 350px;
      }
      .home__main{
          margin-left:300px;
      }
      
      .chart_container{
        width: 437px !important;
      } 
      .chart_{
        width: 400px !important;
      }
  } 
  
  @media screen and (max-width: 1555px) {
    #athleteprofileform{
      position: relative;
      left: 15px;
    }
    .sidebar {
    width: 260px !important;
  }
  .signout__button {
    width: 260px !important;
  }
  .mycal, .custom-calendar{
      max-width: 330px !important;
    }
    .events_container{
        max-width: 330px !important;
    }
    .rightContainer {
      width: 350px !important;
    }
    .home__main{
        margin-left:250px !important ;
    }
    .home__reportsRightInnerContainer{
   
      height: 345px !important;
  }
}
  
  @media screen and (max-width: 1522px) {
    #athleteprofileform{
      position: relative;
      left: 0px;
    }
    .sidebar {
      width: 230px !important;
    }
    .signout__button {
      width: 230px !important;
    }
    .mycal, .custom-calendar{
        max-width: 330px !important;
      }
      .events_container{
          max-width: 330px !important;
      }
      .rightContainer {
        width: 350px !important;
      }
      .home__main{
          margin-left:280px ;
      }      
      .home__reportsRightInnerContainer{
        height: 345px !important;
    }
  }
  
  @media screen and (max-width: 1485px) {
    div.Calendar__sectionWrapper{
      min-height: 255px !important;
    }
    #athleteprofileform{
      position: relative;
      left: 30px;
    }
    #meetlink{
  font-size: small !important;
}
    #homediv{
      width: 82.5vw !important;
    }
    .sidebar {
      width: 220px !important;
    }
    .events_container{
        max-width: 291px !important;
        min-width: 290px !important;
      }
      .mycal, .custom-calendar{
        min-width: 290px !important;
        max-width: 291px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        
        width: 310px !important;
      }
      .home__main{
          margin-left:220px !important;
      }
      .upcoming_event_right {
    margin-right: 40px !important;
  }
  .signout__button {
    width: 220px !important;
  }     
  
  .chart_container{
    width: 467px !important;
  } 
  .chart_{
    width: 430px !important;
  }  
  .home__reportsRightInnerContainer{
    
    height: 358px !important;
}
  }
   
  @media screen and (max-width: 1473px) {
    .sidebar {
      width: 220px !important;
    }
    .events_container{
        max-width: 290px !important;
      }
      .mycal, .custom-calendar{
        max-width: 290px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        width: 310px !important;
      }
      .home__main{
          margin-left:220px !important;
      }
      .upcoming_event_right {
    margin-right: 40px !important;
  }
  
  .chart_container{
    width: 447px !important;
  } 
  .chart_{
    width: 410px !important;
  }  
  .signout__button {
    width: 220px !important;
  }       
  }
  
  @media screen and (max-width: 1442px) {
    
    .sidebar {
      width: 200px !important;
    }
    .events_container{
        max-width: 290px !important;
      }
      .mycal, .custom-calendar{
        max-width: 290px !important;
        font-size: 8.5px;
      }
      .rightContainer {
        width: 310px !important;
      }
      .home__main{
          margin-left:200px !important;
      }
      .drawer__container{
          width: 150px !important;
      }
      .signout__button {
        width: 200px !important;
      }
      .home__reportsRightInnerContainer{
        width: 410px !important;
        height: 349px !important;
    }
  }
 
  @media screen and (max-width: 1418px) {
    div.Calendar__sectionWrapper{
      min-height: 285px !important;
    }
    #athleteprofileform{
      position: relative;
      left: 10px;
    }
    div.coachprofilefomr{
      width: 88vw !important;
    }
    #homediv{
      width: 75.5vw !important;
    }
    #eventsresponsive{
      width: 770px !important;
    }
    .eventsContainerScreen{
      width: 280px !important;
    }
    .sidebar {
      width: 240px !important;
    }
    .events_container{
        max-width: 330px !important;
      }
      .mycal, .custom-calendar{
        max-width: 330px !important;
        font-size: 9px !important;
      }
      .rightContainer {
        width: 350px !important;
      }
      .home__main{
          margin-left:240px !important ;
      }
      .drawer__container{
          width: 170px;
      }
      .home__reportsRightInnerContainer{
          width: 360px !important;
          height: 328px !important;
      }
      .chart_container{
        width: 380px !important;
      } 
      .chart_{
        width: 360px !important;
      }
      
      .eachevent{
        margin-bottom: 20px !important;
      }
      .signout__button {
        width: 240px !important;
      }
  }
  @media screen and (max-width: 1388px) {
    
div.addWorkout__button{
  position: relative;
  right: 10px;
}
    div.Calendar__sectionWrapper{
      min-height: 345px !important;
    }
    #eventsresponsive{
      width: 770px !important;
    }
    .eventsContainerScreen{
      width: 280px !important;
    }
    .sidebar {
      width: 230px !important;
    }
    .events_container{
        max-width: 330px !important;
      }
      .mycal, .custom-calendar{
        max-width: 330px !important;
        font-size: 12px !important;
      }
      .rightContainer {
        width: 350px !important;
      }
      .home__main{
          margin-left:230px !important ;
      }
      .drawer__container{
          width: 160px;
      }
      .home__reportsRightInnerContainer{
          width: 350px !important;
          height: 323px !important;
      }
      .chart_container{
        width: 370px !important;
      } 
      .chart_{
        width: 350px !important;
      }
      
      .eachevent{
        margin-bottom: 20px !important;
      }
      .signout__button {
        width: 230px !important;
      }
  }
  
  @media screen and (max-width: 1350px) { 
    div.Calendar__sectionWrapper{
      min-height: 285px !important;
    }
    #homediv{
      width: 77.5vw !important;
    }
    .sidebar {
      width: 210px !important;
    }
    .events_container{
        max-width: 310px !important;
      }
      .mycal, .custom-calendar{
        max-width: 310px !important;
        font-size: 9px !important;
      }
      .rightContainer {
        width: 330px !important;
      }
      .home__main{
          margin-left:210px !important ;
      }
      .drawer__container{
          width: 150px;
      }
      .home__reportsRightInnerContainer{
          width: 350px !important;
          height: 323px !important;
      }
      .chart_container{
        width: 370px !important;
      } 
      .chart_{
        width: 350px !important;
      }
      .signout__button {
        width: 210px !important;
      }
  }

  
  @media screen and (max-width: 1307px) { 
    div.Calendar__sectionWrapper{
      min-height: 265px !important;
    }
    #homediv{
      width: 79.5vw !important;
    }
    #meetlink{
      font-size: small;
    }
    .sidebar {
      width: 180px !important;
    }
    .events_container{
        max-width: 310px !important;
      }
      .mycal, .custom-calendar{
        max-width: 310px !important;
        font-size: 9px !important;
      }
      .rightContainer {
        width: 330px !important;
      }
      .home__main{
          margin-left:180px !important ;
      }
      .drawer__container{
          width: 140px !important;
      }
      .home__reportsRightInnerContainer{
          width: 350px !important;
          height: 323px !important;
      }
      .chart_container{
        width: 370px !important;
      } 
      .chart_{
        width: 350px !important;
      }
      .signout__button {
        width: 180px !important;
      }
      #eventsresponsive{
        width: 750px !important;
      }
      .eventsContainerScreen{
        width: 250px !important;
      }
  }
  
  @media screen and (max-width: 1278px) { 
    
div.addWorkout__button{
  position: relative;
  right: 0px;
}
    #homediv{
      width: 80.5vw !important;
    }
    .sidebar {
      width: 180px !important;
    }
    .events_container{
        max-width: 280px !important;
      }
      .signout__button {
        width: 170px !important;
      }
      .mycal, .custom-calendar{
        max-width: 280px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        width: 300px !important;
      }
      .home__main{
          margin-left:180px !important ;
      }
      .drawer__container{
          width: 140px !important;
      }
      .home__reportsRightInnerContainer{
          width: 350px !important;
          height: 323px !important;
      }
      .chart_container{
        width: 370px !important;
      } 
      .chart_{
        width: 350px !important;
      }
      
      .upcoming_event_right {
        margin-right: 50px !important;
      }
      #eventsresponsive{
        width: 750px !important;
      }
      .eventsContainerScreen{
        width: 240px !important;
      }
  }
  
  @media screen and (max-width: 1248px) {
     
    #homediv{
      width: 78.5vw !important;
    }
    .sidebar {
      width: 195px !important;
    }
    
    #eventsresponsive{
      width: 690px !important;
    }
    .eventsContainerScreen{
      width: 230px !important;
    }
    .events_container{
        max-width: 280px !important;
      }
      .mycal, .custom-calendar{
        max-width: 280px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        width: 300px !important;
      }
      .home__main{
          margin-left:195px !important ;
      }
      .drawer__container{
          width: 155px !important;
      }
      .home__reportsRightInnerContainer{
          width: 335px !important;
          height: 317px !important;
      }
      .chart_container{
        width: 355px !important;
      } 
      .chart_{
        width: 335px !important;
      }
      .signout__button {
        width: 195px !important;
      }
  }

  @media screen and (max-width: 1235px) {
    
div.addWorkout__button{
  position: relative;
  right: -10px;
}
    .sidebar {
      width: 180px !important;
    }
    
    #eventsresponsive{
      width: 690px !important;
    }
    .eventsContainerScreen{
      width: 230px !important;
    }
    .events_container{
        max-width: 280px !important;
      }
      .mycal, .custom-calendar{
        max-width: 280px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        width: 300px !important;
      }
      .home__main{
          margin-left:180px !important ;
      }
      .drawer__container{
          width: 140px !important;
      }
      .home__reportsRightInnerContainer{
          width: 320px !important;
          height: 310px !important;
      }
      .chart_container{
        width: 340px !important;
      } 
      .chart_{
        width: 320px !important;
      }
      .signout__button {
        width: 180px !important;
      }
  }

  @media screen and (max-width: 1183px) {
    
div.addWorkout__button h5{
  font-size: 11px !important;
}
    div.coachProfile__container ,.profile{
      margin-left: 0% !important;
      width: 59vw !important;
    }
    .sidebar {
      width: 170px !important;
    }
    .events_container{
        max-width: 280px !important;
      }
      .mycal, .custom-calendar{
        max-width: 280px !important;
        font-size: 8.5px !important;
      }
      .rightContainer {
        width: 300px !important;
      }
      .home__main{
          margin-left:170px !important ;
      }
      .drawer__container{
          width: 130px !important;
      }
      .home__reportsRightInnerContainer{
          width: 320px !important;
          height: 310px !important;
      }
      .chart_container{
        width: 330px !important;
      } 
      .chart_{
        width: 320px !important;
      }
      
      .upcoming_event_right {
        margin-right: 50px !important;
      }
      
      .eachevent{
        margin-bottom: 20px !important;
      }        
  }
  
  @media screen and (max-width: 1170px) {

    .sidebar {
      width: 175px !important;
    }
    .events_container{
        max-width: 260px !important;
      }
      .mycal, .custom-calendar{
        max-width: 260px !important;
        font-size: 8px !important;
        font-weight: bold !important;
      }
      .rightContainer {
        width: 280px !important;
      }
      .home__main{
          margin-left:175px !important ;
      }
      .drawer__container{
          width: 135px !important;
      }
      .home__reportsRightInnerContainer{
          width: 325px !important;
          height: 313px !important;
      }
      .chart_container{
        width: 335px !important;
      } 
      .chart_{
        width: 325px !important;
      }
      .events_container{
          padding: 10px !important;
      }
      .eachevent{
        margin-bottom: 20px !important;
      }
  }
  @media screen and (max-width: 1162px) {

    .sidebar {
      width: 170px !important;
    }
    .events_container{
        max-width: 260px !important;
      }
      .mycal, .custom-calendar{
        max-width: 260px !important;
        font-size: 8px !important;
        font-weight: bold !important;
      }
      .rightContainer {
        width: 280px !important;
      }
      .home__main{
          margin-left:170px !important ;
      }
      .drawer__container{
          width: 130px !important;
      }
      .home__reportsRightInnerContainer{
          width: 320px !important;
          height: 306px !important;
      }
      .chart_container{
        width: 329px !important;
      } 
      .chart_{
        width: 309px !important;
      }
      .events_container{
          padding: 10px !important;
      }
      .eachevent{
        margin-bottom: 20px !important;
      }
  }
  @media screen and (max-width: 1135px) { 
    #homediv{
      width: 94.5vw !important;
    }
    div.coachProfile__container ,.profile{
      margin-left: 2% !important;
      width: 69vw !important;
    }
    #eventsresponsive{
      width: 750px !important;
    }
    .eventsContainerScreen{
      width: 270px !important;
    }
    #menutoggle{
      display: block;
    }
    .fab__icon img{
      margin-top: 20px;
    }
    .home__headerFirst{
      margin-top: 20px;
    }
    .sidebarclass {
      display: none;
    }
    .events_container{
        max-width: 290px !important;
      }
      .mycal, .custom-calendar{
        max-width: 290px !important;
        font-size: 8.5px !important;
        font-weight: bold !important;
      }
      .rightContainer {
        width: 310px !important;
      }
      .home__main{
          margin-left:0px !important ;
       
      }
      .drawer__container{
          width: 180px !important;
      }
      .home__reportsRightInnerContainer{
          width: 360px !important;
          height: 328px !important;
      }
      .chart_container{
        width: 380px !important;
      } 
      .chart_{
        width: 360px !important;
      }
      .events_container{
          padding: 10px !important;
      }
      .eachevent{
        margin-bottom: 20px !important;
      }
  }

  @media screen and (max-width: 1095px) {
     
    #homediv{
      width: 92.5vw !important;
    }
    #meetlink{
      font-size: x-small;
    }
        .events_container{
        max-width: 290px !important;
      }
      .mycal, .custom-calendar{
        max-width: 290px !important;
        font-size: 8.5px !important;
        font-weight: bold !important;
      }
      .rightContainer {
        width: 310px !important;
      }
      .home__reportsRightInnerContainer{
          width: 340px !important;
          height: 319px !important;
      }
      .chart_container{
        width: 360px !important;
      } 
      .chart_{
        width: 340px !important;
      }
      .events_container{
          padding: 10px !important;
      }
      .eachevent{
        margin-bottom: 20px !important;
      }
  }
  
  @media screen and (max-width: 1062px) {
    div.Calendar__sectionWrapper{
      min-height: 235px !important;
    }
    .events_container{
    max-width: 270px !important;
  }
  .mycal, .custom-calendar{
    max-width: 270px !important;
    font-size: 8px !important;
    font-weight: bold !important;
  }
  .rightContainer {
    width: 290px !important;
  }
  .home__reportsRightInnerContainer{
      width: 340px !important;
      height: 321px !important;
  }
  .chart_container{
    width: 365px !important;
  } 
  .chart_{
    width: 345px !important;
  }
  .events_container{
      padding: 10px !important;
  }
  .eachevent{
    margin-bottom: 20px !important;
  }
}
  @media screen and (max-width: 1049px) {
    .events_container{
    max-width: 270px !important;
  }
  .mycal, .custom-calendar{
    max-width: 270px !important;
    font-size: 8px !important;
    font-weight: bold !important;
  }
  .rightContainer {
    width: 290px !important;
  }
  .home__reportsRightInnerContainer{
      width: 340px !important;
      height: 315px !important;
  }
  .chart_container{
    width: 360px !important;
  } 
  .chart_{
    width: 330px !important;
  }
  .events_container{
      padding: 10px !important;
  }
  .eachevent{
    margin-bottom: 20px !important;
  }
}
@media screen and (max-width: 1027px) {
  
  #eventsresponsive{
    width: 700px !important;
  }
  .eventsContainerScreen{
    width: 200px !important;
  }
  .events_container{
  max-width: 270px !important;
}
.mycal, .custom-calendar{
  max-width: 270px !important;
  font-size: 8px !important;
  font-weight: bold !important;
}
.rightContainer {
  width: 290px !important;
}
.home__reportsRightInnerContainer{
    width: 320px !important;
    height: 306px !important;
}
.chart_container{
  width: 340px !important;
} 
.chart_{
  width: 310px !important;
}
.events_container{
    padding: 10px !important;
}
.eachevent{
  margin-bottom: 20px !important;
}
}


@media screen and (max-width: 990px) {
  div.Calendar__sectionWrapper{
    min-height: 215px !important;
  }
  #homediv{
    width: 95.5vw !important;
  }
  div.upcoming_event_title{
    font-size: 14px !important;
  }
  .upcoming_event_right {
    margin-right: 70px !important;
    width: max-content !important;
  }
  #eventshistoryspan{
    font-size: small;
  }
  .events_container{
  max-width: 240px !important;
}
.mycal, .custom-calendar{
  max-width: 240px !important;
  font-size: 7px !important;
  font-weight: bold !important;
}
.rightContainer {
  width: 260px !important;
}
.home__reportsRightInnerContainer{
    width: 320px !important;
    height: 310px !important;
}
.chart_container{
  width: 340px !important;
} 
.chart_{
  width: 310px !important;
}
.events_container{
    padding: 10px !important;
}
.eachevent{
  margin-bottom: 20px !important;
}
}
@media screen and (max-width: 963px) {
  div.Calendar__sectionWrapper{
    min-height: 295px !important;
  }
  #homediv{
    
    width: 139.5vw !important;
  }
  div.coachProfile__container ,.coachProfile__leftContainer,.profile,.profile__container,.profile__main,.profile,.home__main{
    padding: 0% !important;
    margin-left: 0% !important;
    width: 100% !important;
  }
  #meetlink{
    font-size: large;
  }
  #eventsresponsive{
    width: 880px !important;
  }

  .eventsContainerScreen{
    width: 380px !important;
  }
  #bell,
  #calendericon{
    display: block;
  }
  
  div.upcoming_event_title{
    font-size: 17px !important;
  }
  body,html{
    background-color: #f3f3f3;
  }
  .upcoming_event_right {
    margin-right: 10px !important;
    width: max-content !important;
  }
  #eventshistoryspan{
    font-size: large;
  }
  .events_container{
  max-width:630px !important;
}
.mycal, .custom-calendar{
  background-color: white;
  max-width: 630px !important;
  font-size: 10px !important;
  font-weight: bold !important;
}
.rightContainer {
  display: none;
  width: 650px !important;
}
.home__reportsRightInnerContainer{
    
    height: 367px !important;
}
.chart_container{
  width: 510px !important;
} 
.chart_{
  width: 450px !important;
}
.events_container{
    padding: 10px !important;
}
.eachevent{
  margin-bottom: 20px !important;
}
}

@media screen and (max-height: 730px) {
  #scrolldiv{
    max-height: 380px;
  }
}
@media screen and (max-height: 703px) {
  #scrolldiv{
    max-height: 350px;
  }
}

@media screen and (max-height: 673px) {
  #scrolldiv{
    max-height: 320px;
  }
}
@media screen and (max-height: 629px) {
  #scrolldiv{
    max-height: 300px;
  }
}
@media screen and (max-height: 610px) {
  #scrolldiv{
    max-height: 260px;
  }
}
@media screen and (max-width: 3000px) {
  #tophead{
    display: none !important;
  }
  #bottomhead{
    display: block !important;
  }
}

@media screen and (max-width: 1135px) {
  #tophead{
    display: block !important;
  }
  #bottomhead{
    display: none !important;
  }
}