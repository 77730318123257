#newassessment {
  margin-left: 25% !important;
}
@media screen and (max-width: 1758px) {
  #newassessment {
    margin-left: 15% !important;
  }
}
@media screen and (max-width: 1678px) {
  #newassessment {
    margin-left: 10% !important;
  }
}
@media screen and (max-width: 1418px) {
  #newassessment {
    position: relative;
    right: 20px;
    margin-left: 0% !important;
    font-size: 15px;
  }
}
@media screen and (max-width: 1218px) {
  #newassessment {
    position: relative;
    right: 20px;
    margin-left: 0% !important;
    font-size: 14px;
  }
}

@media screen and (max-width: 963px) {
  div.sameline {
    width: 100vw !important;
    margin-top: 50px;
  }
  #newassessment {
    position: relative;

    margin-left: 10% !important;
    font-size: 14px;
  }
}

.prevtherapy {
  width: max-content;
  height: max-content;
  inline-size: max-content;
  background-color: #ee664f;
  border-radius: 12px;
  text-align: center;
  margin-left: 10px;
}
/* .main button {
  /* margin-top: 20px; */
/* background-color: #ee664f; Green */
/* border: none;
  color: white; */
/* padding: 15px 32px; */
/* text-align: center;
  text-decoration: none;
} */
.sameline {
  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  background-color: #ee664f;
  width: 380px;
  height: 50px;
  border-radius: 15px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: white;
}

.main {
  margin-top: 20px;
  margin-left: 20px;
}
