.rightbtn{
  margin-left: 76%;
}
@media screen and (max-width: 2222px) {
  #firstweek{
    width: 319px !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (max-width: 963px) {
  .coachCreateWorkout, #assignworkout{
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }  
}
@media screen and (max-width: 865px) {
   #assignworkout{
    width: 800px !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }  
}

.workouts_header {
  margin: 20px;
}
.workouts_header div {
  width: 100%;
  display: flex;
}

.workouts_header {
  display: flex;
  justify-content: center;
  flex-direction: row;
}

.header_item {
  background-color: var(--primarycolor);
  width: 30px;
  color: white;
  height: 30px;
  border-radius: 15px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

hr {
  height: 2px;
  border-top: 1px dotted red;
}
.coachCreateWorkout {
  margin-left: 0px;
}
.header_line {
  border-top: 1px solid #707070;
}

.Workouts_body {
  margin: 20px;
}
.workouts_body div input {
  width: 100%;
}
.Dotted_line {
  width: 90%;
  box-sizing: border-box;
  margin-right: 20px;

  border-color: rgba(0, 0, 0, 0.575);
  border-top-style: dashed;
}

#customized-hook-demo1-popup {
  margin-left: 0 !important;
}

.sc-gtsrHT .iYKfNu {
  margin-bottom: 0;
}

.select-search__select {
  height: 187px !important;
  border-radius: 10px;
}

.MuiInputBase-input .MuiFilledInput-input {
  background-color: white;
}

.select-search .sets {
  width: 50%;
  margin-left: 20px;
}
