#vodhome,
#vodresp {
  margin-top: 20px;
}
#athletediv {
  margin-top: 40px;
}
#messaging {
  margin-top: 20px;
}
@media screen and (max-width: 2000px) {
  .vodmain,
  #allath,
  .workouts__home,
  .workouts__home,
  #assignworkout,
  .viewallsavedworkouts,
  .coachCreateWorkout,
  #savedworkouts,
  #assignworkouts,
  .createNutrition,
  #messaging,
  #chatcontainer,
  #vodresp,
  #athletediv,
  .Postworkout__container {
    position: relative;
    left: 11px;
  }
  .assignvideopage {
    position: relative;
    left: -20px;
  }
  #vodresp {
    position: relative;
    left: 10px;
  }
}
@media screen and (max-width: 1850px) {
  .messagebarcontainer {
    margin-right: 10px;
  }
  .vodmain,
  #vodresp,
  #allath,
  .workouts__home,
  .workouts__home,
  #assignworkout,
  .coachCreateWorkout,
  #savedworkouts,
  #assignworkouts,
  .createNutrition,
  #messaging,
  #chatcontainer,
  #athletediv,
  .viewallsavedworkouts,
  .Postworkout__container {
    position: relative;
    left: 10px;
  }
  #vodresp {
    position: relative;
    left: 10px;
  }
}

@media screen and (max-width: 1555px) {
  .messagebarcontainer {
    margin-right: 0px;
  }
  .vodmain,
  #vodresp,
  #allath,
  .workouts__home,
  .workouts__home,
  #assignworkout,
  .coachCreateWorkout,
  #savedworkouts,
  #assignworkouts,
  #messaging,
  #chatcontainer,
  #athletediv,
  .viewallsavedworkouts,
  .Postworkout__container {
    position: relative;
    left: 9.5px;
  }
  #vodresp {
    position: relative;
    left: 10px;
  }
}
@media screen and (max-width: 1430px) {
  .messagebarcontainer {
    margin-right: 15px;
  }
}
@media screen and (max-width: 1418px) {
  .messagebarcontainer {
    margin-right: 25px;
  }
}
@media screen and (max-width: 1520px) {
  .vodmain,
  #vodresp,
  #allath,
  .workouts__home,
  .workouts__home,
  #assignworkout,
  .coachCreateWorkout,
  #savedworkouts,
  #assignworkouts,
  .createNutrition,
  #messaging,
  #chatcontainer,
  #athletediv,
  .viewallsavedworkouts,
  .Postworkout__container {
    position: relative;
    left: 9px;
  }
  .assignvideopage {
    position: relative;
    left: 0px;
  }
  #vodresp {
    position: relative;
    left: -10px;
  }
}
@media screen and (max-width: 1384px) {
  #iframe {
    width: 310px !important;
  }
  .vodmain,
  #allath,
  .workouts__home,
  .workouts__home,
  #assignworkout,
  .coachCreateWorkout,
  #savedworkouts,
  #assignworkouts,
  .createNutrition,
  #messaging,
  #chatcontainer,
  #athletediv,
  .Postworkout__container {
    position: relative;
    left: 0px;
  }
  div.assignvideopage {
    position: relative;
    left: -20px !important;
  }
  .assignvideopage {
    position: relative;
    left: -22px;
  }
  #vodresp {
    left: 3px;
  }
}
@media screen and (max-width: 1224px) {
  #iframe {
    width: 310px !important;
  }
}
@media screen and (max-width: 1200px) {
  #iframe {
    width: 330px !important;
  }
}

@media screen and (max-width: 1135px) {
  #iframe {
    width: 400px !important;
  }
}

@media screen and (max-width: 1078px) {
  #iframe {
    width: 370px !important;
  }
}
@media screen and (max-width: 1058px) {
  #iframe {
    width: 320px !important;
  }
}

@media screen and (max-width: 1007px) {
  #iframe {
    width: 340px !important;
  }
}
@media screen and (max-width: 963px) {
  .vodmain {
    position: relative;
    left: 7.5px;
  }
  #iframe {
    width: 440px !important;
    height: 230px;
  }
  .vodmain,
  #vodresp {
    min-width: 90vw !important;
    max-width: 91vw !important;
    position: relative;
    z-index: 1;
    margin-top: 50px !important;
  }
  #inputtag {
    width: 92vw !important;
  }

  #inputtagg {
    width: 92vw !important;
  }
}
@media screen and (max-width: 863px) {
  #iframe {
    width: 300px !important;
  }
  .vodmain {
    min-width: 700px !important;
    max-width: 720px !important;
    position: relative;
    z-index: 1;
    margin-top: 20px;
  }
}
.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}

.videoupload {
  margin-left: 10px;
}

.videoupload:hover {
  cursor: pointer;
}
#filelabel:hover {
  cursor: pointer;
}

.vod_input {
  padding: 10px;
  margin-left: 10px;
  margin-right: 10px;
  box-sizing: border-box;
  width: 100%;
}

.button__assignVideo:hover {
  cursor: pointer;
  color: var(--secondarycolor);
  background-color: var(--primarycolor);
}
@media (min-width: 1385px) and (max-width: 1420px) {
  #iframe {
    width: 26vw !important;
    padding-left: 0% !important;
    padding-right: 0% !important;
    position: relative;
  }
}
