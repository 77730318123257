@media screen and (max-width: 963px) {
    #contactmanage,#manageblogs,#uniqueforms{
      width: 96vw !important;
      margin-right: 20px;
      margin-left: 20px;
      position: relative;
      margin-top: 50px;
    }  
    #inputtag{
      width: 92vw !important;
    }
    
    #inputtagg{
      width: 92vw !important;
    }
  }
  #contactmanage,#manageblogs,#uniqueforms{
    margin-left: 15px !important;
  }
  #theformone{
    padding-bottom: 0%;
    padding-top: 0%;
    padding: 3%;
    width: 94%;
  }