.sidebar {
  height: 99.9vh;
  border: 1px solid #d3d3d3;
  position: fixed;
  max-width: 300px;
}

.sidebar__container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 30px;
  text-align: center;
}

.sidebar__container > img {
  border-radius: 100px !important;
  background-color: grey;
  object-fit: cover;
}

.sidebar__container > h1 {
  font-size: 18px;
  line-height: 18px;
  letter-spacing: 1px;
  font-weight: bold;
  color: #003049;
  text-align: center;
}
.sidebar__container > h3 {
  font-size: 13px;
  line-height: 17px;
  font-weight: 600;
  color: #003049;
  text-align: center;
}

.view-link {
  font-size: 12px;
  text-decoration: none;
  color: #003049;
  margin-bottom: 15px;
}

.view-link:hover {
  text-decoration: underline;
  cursor: pointer;
}

.drawer__container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
  padding-right: 20px;
  background-color: var(--primarycolor);
  color: var(--secondarycolor);
  height: 30px;
  width: 180px;
  margin: 15px 0;
  border-radius: 12px;
  cursor: pointer;
}

.drawer__container:hover {
  background-color: var(--hovercolor);
  box-shadow: 2px 4px #e6e6e6;
}

.drawer__container > h1 {
  font-size: 14px;
  font-weight: 600;
}

.drawer__container > img {
  margin-left: 20px;
  margin-right: 30px;
}

.signout__button {
  width: 265px;
  background-color: #4b4b4b;
  height: 44px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 0;
}

.signout__button > h2 {
  font-size: 16px;
  line-height: 24px;
  color: #f6f6f6;
  font-weight: 400;
}

#scrolldiv {
  max-height: 480px !important;
  overflow: scroll;
  padding-right: 11px;
}

@media screen and (max-height: 713px) {
  #scrolldiv {
    max-height: 420px !important;
  }
}

@media screen and (max-height: 670px) {
  #scrolldiv {
    max-height: 350px !important;
  }
}
@media screen and (max-height: 443px) {
  #scrolldiv {
    max-height: 170px !important;
  }
}

@media screen and (max-height: 650px) {
  #scrolldiv {
    max-height: 350px !important;
  }
}
@media screen and (max-height: 600px) {
  #scrolldiv {
    max-height: 310px !important;
  }
}
#scrolldiv::-webkit-scrollbar {
  width: 2px;
  max-height: 50% !important;
}

@media screen and (max-height: 550px) {
  #scrolldiv {
    max-height: 260px !important;
  }
}

@media screen and (max-height: 500px) {
  #scrolldiv {
    max-height: 220px !important;
  }
}

@media screen and (max-height: 456px) {
  #scrolldiv {
    max-height: 190px !important;
  }
}

@media screen and (max-height: 443px) {
  #scrolldiv {
    max-height: 150px !important;
  }
}

/* Track */
#scrolldiv::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
  max-width: 3px !important;
  max-height: 2px !important;
}

/* Handle */
#scrolldiv::-webkit-scrollbar-thumb {
  background: rgba(104, 104, 104, 0.863);
  border-radius: 10px;
  max-width: 3px !important;
  max-height: 3px !important;
}

/* Handle on hover */
#scrolldiv::-webkit-scrollbar-thumb:hover {
  background: #813e3e;
}
