@media screen and (max-width: 2000px){
  #addmealheader{
    position: relative;
    left: 10px;
  }
}
@media screen and (max-width: 1400px) {
.togglefood{
position: relative;
right: 30px;
}
}
  @media screen and (max-width: 963px) {
    .coachNutritionHome{
      width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
      position: relative;
      z-index: 1;
      margin-top: 20px;
    }  
  }

.coachFoodCard__addmealButton > h3,
.coachFoodCard__submitMealButton > h3 {
  font-size: 14px;
  font-weight: 600;
}

.kqbjnw {
  margin-left: 0 !important;
}
.makeStyles-formControl-1 {
  padding: 0 !important;
  margin: 0 !important;
  margin-bottom: 10px !important;
}
.coachFoodCard__addmealButton {
  height: 45px;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primarycolor);
  border-radius: 8px;
  cursor: pointer;
}
.coachFoodCard__submitMealButton {
  height: 40px;
  width: 270px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-left: 20px;
  margin-right: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primarycolor);
  border-radius: 8px;
  cursor: pointer;
}

.MuiInput-underline:before {
  content: "" !important;
  border: none !important;
}
.MuiInputBase-root
  .MuiInput-root
  .MuiInput-underline
  .MuiInputBase-formControl
  .MuiInput-formControl {
  border: 1px solid black !important;
}
#servings-select-label {
  border: none !important;
}
label {
  border: none;
}

#servings-select-label {
}
.MuiSelect-select.MuiSelect-select {
  border: 1px solid black;
  padding: 10px 10px;
}
.MuiFormControl-root {
  width: 100%;
}
.MuiAutocomplete-root
  .MuiAutocomplete-hasClearIcon
  .MuiAutocomplete-hasPopupIcon {
  width: 100%;
}

.MuiInputBase-root {
  background-color: white;
}

input {
  border: none;
  box-shadow: 0px 0px 5px 2px rgb(0 0 0 / 10%);
}

.MuiInput-underline {
  border: none;
}

.MuiInput-underline > input {
  border: none;
}
.MuiInput-underline:before {
  border: none;
  box-shadow: none;
}

.Datepicker__container {
  border: 1px solid #777 !important;
}
