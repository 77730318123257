
@media screen and (max-width: 2000px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
    background-color: #fff;
    border-radius: 8px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
  }
  .waterCard{
    height: 100px;  }
  .dashboardclass{height: 80px !important;
  margin-top: 8px !important;
  margin-bottom: 0% !important;
  border-radius: 10px !important;
  }
    
  #dashboardsealltwo{
    position: relative;
    right: 40px;
  }
}

@media screen and (max-width: 1800px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass,div.athletes__card {
    width: 24vw !important;
  }
}
@media screen and (max-width: 1570px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 23vw !important;
  }
  div.athletes__card{
    width: 22vw !important;
  }
}

@media screen and (max-width: 1555px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 27vw !important;
  }
  div.athletes__card{
    width: 26vw !important;
  }
}
@media screen and (max-width: 1527px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
  }
  div.athletes__card{
    width: 25vw !important;
  }
}
@media screen and (max-width: 1510px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 24vw !important;
  }
  div.athletes__card{
    width: 23vw !important;
  }
}

@media screen and (max-width: 1485px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 27vw !important;
  }
  div.athletes__card{
    width: 26vw !important;
  }
}

@media screen and (max-width: 1450px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
  }
  div.athletes__card{
    width: 25.3vw !important;
  }
}
@media screen and (max-width: 1420px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 24.9vw !important;
  }
  div.athletes__card{
    width: 24.2vw !important;
  }
}

@media screen and (max-width: 1388px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 25vw !important;
  }
  div.athletes__card{
    width: 24.3vw !important;
  }
}

@media screen and (max-width: 1360px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 24.5vw !important;
  }
  div.athletes__card{
    width: 24vw !important;
  }
}

@media screen and (max-width: 1350px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
  }
  div.athletes__card{
    width: 25.3vw !important;
  }
}

@media screen and (max-width: 1320px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 25vw !important;
  }
  div.athletes__card{
    width: 24.3vw !important;
  }
}
@media screen and (max-width: 1308px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26.5vw !important;
  }
  div.athletes__card{
    width: 25.8vw !important;
  }
}
@media screen and (max-width: 1295px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 25vw !important;
  }
  div.athletes__card{
    width: 24.5vw !important;
  }
}
@media screen and (max-width: 1270px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
  }
  div.athletes__card{
    width: 25.4vw !important;
  }
}
@media screen and (max-width: 1250px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 25.3vw !important;
  }
  div.athletes__card{
    width: 24.8vw !important;
  }
}
@media screen and (max-width: 1235px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 26vw !important;
  }
  div.athletes__card{
    width: 25.4vw !important;
  }
}

@media screen and (max-width: 1200px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 25vw !important;
  }
  div.athletes__card{
    width: 24.5vw !important;
  }
}

@media screen and (max-width: 1135px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 31vw !important;
  }
  div.athletes__card{
    width: 30.4vw !important;
  }
}
@media screen and (max-width: 1110px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 30vw !important;
  }
  div.athletes__card{
    width: 29.4vw !important;
  }
}
@media screen and (max-width: 1050px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 29vw !important;
  }
  div.athletes__card{
    width: 28.3vw !important;
  }
}
@media screen and (max-width: 1000px) {
  .waterCard,div.nutritionGoalProgress,.dashboardclass {
    width: 29vw !important;
  }
  div.athletes__card{
    width: 28.4vw !important;
  }
}

@media screen and (max-width: 963px) {
  .dashboardclass {
    width: 91.6% !important;
  }
  .waterCard,div.nutritionGoalProgress {
    width: 98% !important;
  }
  div.athletes__card{
    width: 98% !important;
  }
}
.waterCard__container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* margin-right: 10px; */
}

.waterCard__container > h4 {
  font-size: 16px;
  font-weight: 600;
}

.waterCard__buttons {
  display: flex;
  align-items: center;
  justify-content: center;
}

.waterCard__button {
  margin: 0;
  margin-right: 15px;
  background-color: var(--primarycolor);
  border-radius: 999px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 24px;
  font-weight: "bold";
  margin-bottom: 2px;
  text-align: center;
  border-width: 0;
  color: var(--secondarycolor);
  padding: 2%;
}

.waterCard > h4 {
  font-size: 12px;
  margin-top: 10px;
}

.waterCard__progress {
  margin-left: 15px;
}
