/* Hide scrollbar for Chrome, Safari and Opera */
#scrolldivs::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
#scrolldivs {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

#chatcontainer{
  margin-top: 20px;
}
@media screen and (max-width: 963px) {
  #chatcontainer{
    width: 90vw !important;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    margin-top: 20px;
    z-index: 1;
  }  
}
@media screen and (max-width: 963px) {
 #messaging{
  width: 97.4vw !important;
  padding-left: 1%;
  padding-right: 1%;
    position: relative;
    z-index: 1;
    margin-top: 10px !important;
    border-right: 3px solid black;
  }  
}
.messaging__sendMessage > img {
  position: absolute;
  right: 10px;
  cursor: pointer;
  top: 9px;
}
.messaging__gallery > img {
  position: absolute;
  right: 50px;
  cursor: pointer;
  top: 9px;
}

.messaging__leftArrow > img {
  cursor: pointer;
}

.messaging__bar {
  display: flex;
  align-items: center;
}

.messaging__bar > input {
  padding: 10px;
  display: flex;
  width: 100%;
  border: none;
  outline: gray;
  background-color: white;
}

.chatCard {
  display: flex;
  align-items: center;
  background-color: white;
  margin-bottom: 15px;
  border-radius: 10px;
  height: 70px;
  margin-left: 15px;
  margin-right: 15px;
  cursor: pointer;
}

.athlete_card {
  display: flex;
  align-items: center;
}

.athlete_image {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin-right: 20px;
  margin-left: 10px;
}

.athlete_name {
  font-size: 17px;
  line-height: 17px;
  font-weight: 600;
}

.message_icon > img {
  height: 20px;
  width: 20px;
}

.athlete_message > h3 {
  font-size: 15px;
  font-weight: 400;
  line-height: 18px;
}

.athlete_list {
  display: flex;
  align-items: center;
  background-color: var(--primarycolor);
  border-radius: 10px;

  cursor: pointer;
  align-self: flex-end;
}
