.home__container {
  width: 100%;
  height: 100%;
  /* flex: 1; */
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f3f3f3;
}

.home__main {
  /* flex: 0.7; */
  height: 100%;
  margin-left: 290px;
  
}

.home__rightContainer {
  border: 1px solid #d3d3d3;
  height: 100%;
  /* flex: 0.3; */
  /* display: flex; */
  /* flex-direction: column;
  align-items: flex-start;
  justify-content: center; */
}

@font-face {
  font-family: "Open_Sans";
  src: local("Open_Sans"),
    url("./fonts/Open_Sans/OpenSans-Regular.ttf") format("truetype");
  font-weight: normal;
}

@font-face {
  font-family: "Montserrat";
  src: local("Montserrat"),
    url("./fonts/Montserrat/Montserrat-Regular.ttf") format("truetype");
  font-weight: normal;
}

* {
  font-family: "Open_Sans";
}

.devicebutton:hover {
  cursor: pointer;
}
.dialogflex{
  display: flex;
  flex-direction: row;

  justify-content: center;
}
.dialogflexone{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.dialogflextwo{
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
}

.dialogsignout{
  position: relative;
  padding: 0%;
  color: rgb(0, 0, 0);
  background-color:rgb(197, 197, 197) !important;
  margin-bottom: 10px !important;
}
#bell{
  position: absolute;
  top: 10px;
  right: 30px;
  left: 50px;
}
#calendericon{
  position: absolute;
  top: 10px;
  left: 90px;
}
