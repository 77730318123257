.addMealfood__container {
  margin: 20px;
}

.addMealfood__searchableDropdown {
  margin-left: 8px;
  margin-top: 10px;
}

.foodCard__addButtons {
  display: flex;
  align-items: center;
}

.foodCard__addfoodButton,
.foodCard__addmealButton {
  height: 100%;
  width: 200px;
  margin-top: 20px;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primarycolor);
  border-radius: 8px;
  margin-right: 15px;
  cursor: pointer;
  color: white;
}

.foodCard__addfoodButton > h3,
.foodCard__addmealButton > h3 {
  font-size: 14px;
  font-weight: 600;
}

.makeStyles-formControl-16 {
  margin: 0 !important;
  margin-bottom: 10px !important;
}

.MuiSelect-select.MuiSelect-select {
  border-radius: 5px;
}

.MuiFormControl-root {
  display: flex;
  flex-direction: row;
}

.MuiFormControl-root .makeStyles-formControl-60 {
  display: flex;
  flex-direction: row;
}

.MuiSelect-root
  .MuiSelect-select
  .MuiSelect-selectMenu
  .MuiInputBase-input
  .MuiInput-input {
  width: 250px;
  margin-left: 30px;
}
