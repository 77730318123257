.sleep {
  background-color: white;
  padding: 20px;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
}

.soreness > h4 {
  font-weight: 500;
}

.emoji__div > span {
  margin-top: 10px;
  font-size: 12px;
}

.emoji__container {
  display: flex;
  align-items: center;
  justify-content: space-around;

  margin: 10px 0;
  height: 30px;
  padding-bottom: 20px;
}

.emoji {
  height: 5px;
}

.emoji__div {
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40px;
}

@media screen and (max-width: 963px) {
  #sleepslider{
    width: 85% !important;
  }}
 
   
@media screen and (max-width: 2000px) {
  #sleepeditbtn{
    position: absolute;
    left: -90px;
  }
  #sleepslider{
    width: 100% !important;
  }
}
@media screen and (max-width: 1418px) {
  #sleepslider{
    width: 80% !important;
  }
}
 
@media screen and (max-width: 1418px) {
  #sleepslider{
    width: 70% !important;
  }
}
@media screen and (max-width: 963px) {
  #sleepslider{
    width: 100% !important;
  }
}
@media screen and (max-width: 855px) {
  #sleepslider{
    width: 80% !important;
  }
}
 