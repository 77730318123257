@media screen and (max-width: 963px) {
    #websiteid,#initialpage,#createupdate{
      width: 96vw !important;
      margin-right: 20px;
      margin-left: 20px;
      position: relative;
      margin-top: 80px !important;
    }  
    #inputtag{
      width: 92vw !important;
    }
    
    #inputtagg{
      width: 92vw !important;
    }
  }
  div#websiteid,#initialpage,#createupdate,h1,h3,h4,p,span,label,h2,h5,h6{
    font-family: 'Lato', sans-serif !important;

  }
  #websiteid,#initialpage,#createupdate{
    margin-left: 10px !important;
  }