@media screen and (max-width: 963px) {
  #homediv {
    width: 124vw !important;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    margin-top: 20px;
    z-index: 1;
  }
}
@media screen and (max-width: 963px) {
  #athletediv {
    width: 999px !important;
    padding-left: 1%;
    padding-right: 1%;
    position: relative;
    margin-top: 20px;
    z-index: 1;
  }
}
.home__main {
  flex: 0.7;
  height: 100%;
  margin-left: auto;
}
.plusimage {
  margin-right: 40px;
}
.home__container {
  width: 100%;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  background-color: #f3f3f3;
}

.coachHome__container,
.athleteHome__container {
  display: flex;
  align-items: center;
  height: 100%;
  flex: 1;
  padding-bottom: 10px;
  /* overflow: auto; */
}

.home__leftContainer {
  flex: 0.75;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  flex-wrap: wrap;
  height: 100%;
  width: 100%;
  padding: 0 20px;
}

.home__rightContainer {
  flex: 0.25;
  border: 1px solid #d3d3d3;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.home__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.home__headerFirst {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
}

.home__headerFirst > h1 {
  font-size: 23px;
  font-weight: bold;
  line-height: 33px;
  margin-right: 20px;
}
.home__headerFirst > h2 {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
}

.home__reports {
  width: 100%;
}

.home__reports > h1 {
  font-size: 27px;
  font-weight: 400;
}

.home__reportsMainContainer {
  /* flex: 1; */
  position: relative;
  top: -60px;
  margin-top: 0px !important;
  padding-top: 0px !important;
  display: flex;
  width: max-content;
  margin-bottom: 30px;
}

.home__reportsLeftContainer {
  flex: 0.5;
  /* padding-right: 20px; */
}

.home__reportsLeftContainer > h1,
.coachDashboard__leftContainer > h2,
.coachDashboard__rightContainer > h2,
.home__reportsRightContainer > h1 {
  font-size: 19px;
  font-weight: 600;
  line-height: 32px;
}

.coachDashboard__leftContainer > h1 {
  font-size: 27px;
  font-weight: 400;
  line-height: 30px;
}

.home__reportsRightContainer {
  flex: 0.5;
  /* padding-left: 20px; */
}

.coachDashboard__leftContainer {
  flex: 0.5;
}

/* .coachDashboard__container {
  display: flex;
  flex: 1; 
  flex-direction: column;
}*/

/* .coachDashboard__rightContainer {
  flex: 0.5;
  padding-left: 40px;
  margin-top: 15px;
} */

.messaging {
  width: 90%;
  /* height: 110px; */
  display: flex;
  padding: 10px;
  align-items: center;
  justify-content: space-around;
}

.messaging__component {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  padding-right: 15px;
  background-color: var(--primarycolor);
  color: var(--secondarycolor);
  height: 30px;
  width: 150px;
  margin: 10px 0;
  border-radius: 12px;
  font-size: 12px;
}
.messaging__component:hover {
  cursor: pointer;
}

.see_more_home {
  cursor: pointer;
}
.athletes__card {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
  border-radius: 8px;
  padding-right: 20px;
  height: 140px;
}

.athletes__card > img {
  cursor: pointer;
}

.athletes__cardInfo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.athletes__cardInfo > img {
  border-radius: 100px !important;
  margin: 0 20px;
  background-color: #dddd;
}

.athletes__cardInfo > h4 {
  font-weight: 500;
  font-size: 14px;
}

.athleteGoals__noGoals {
  width: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}

.fab__icon:hover {
  cursor: pointer;
}

.modal__coachComponents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  height: 230px;
  width: max-content;
  /* margin-left: 90px; */
}

.modal__addAthelete,
.modal__createNutritionPlans,
.modal__createWorkout,
.modal__createEvent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
}

.modal__addAthleteImg,
.modal__createEventImg,
.modal__createWorkoutImg,
.modal__createNutritionPlansImg {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: var(--primarycolor);
  color: black;
  justify-content: center;
}

.modal__addAthelete > h3,
.modal__createNutritionPlans > h3,
.modal__createWorkout > h3,
.modal__createEvent > h3,
.modal__addWorkout > h3,
.modal__addGoal > h3,
.modal__addMeal > h3,
.modal__logWeight > h3,
.modal__viewReport > h3 {
  font-size: 18px;
  font-weight: 600;
  line-height: 20px;
}

.modal__addAthleteImg > img,
.modal__createEventImg > img,
.modal__createNutritionPlansImg > img,
.modal__createWorkoutImg > img,
.modal__addWorkoutImg > img,
.modal__addMealImg > img,
.modal__addGoalImg > img,
.modal__logWeightImg > img,
.modal__viewReportImg > img {
  margin-top: 11px;
  margin-left: 28%;
}

.modal__closeButton {
  position: absolute;
  top: 30px;
  right: 40px;
}

.modal__addWorkout,
.modal__addMeal,
.modal__logWeight,
.modal__viewReport,
.modal__addGoal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
}

.modal__addWorkoutImg,
.modal__addMealImg,
.modal__logWeightImg,
.modal__viewReportImg,
.modal__addGoalImg {
  height: 45px;
  width: 45px;
  border-radius: 50%;
  background-color: var(--primarycolor);
  color: black;
  justify-content: center;
}

.modal__addGoalImg > img,
.modal__logWeightImg > img,
.modal__viewReportImg > img {
  height: 20px;
  width: 20px;
}

.modal__athleteComponents {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-top: 50px;
  height: 230px;
  width: max-content;
  /* margin-left: 90px; */
}

.chatIcon {
  cursor: pointer;
}
.athlestes__list {
  border-radius: 10px;
}

.athlestes__list::-webkit-scrollbar {
  width: 1px;
}

.athlestes__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.athlestes__list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

.coach__list::-webkit-scrollbar {
  width: 1px;
}

.coach__list::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.coach__list::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
/*   sdgsdfg   */

.athleteGoals {
  border-radius: 10px;
}

.athleteGoals::-webkit-scrollbar {
  width: 1px;
}

.athleteGoals::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.athleteGoals::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
