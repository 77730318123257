#allath{
    margin-top: 20px;
}
#athletehistory{
    margin-top: 40px;
}

@media screen and (max-width: 2000px) {
    #allath,#chatcontainer{
     width: 58.5vw;
    }    
  }
  
@media screen and (max-width: 1700px) {
    #allath,#chatcontainer{
     width: 57vw;
    }    
  }
  
@media screen and (max-width: 1500px) {
    #allath,#chatcontainer{
     width: 58.5vw;
    }    
  }
  
@media screen and (max-width: 1485px) {
    #allath,#chatcontainer{
     width: 62.5vw;
    }    
  }
  
@media screen and (max-width: 1418px) {
    #allath,#chatcontainer{
     width: 57.3vw;
    }    
  }
  
@media screen and (max-width: 1400px) {
    #allath,#chatcontainer{
     width: 56vw;
    }    
  }
  
@media screen and (max-width: 1350px) {
    #allath,#chatcontainer{
     width: 58.5vw;
    }    
  }
  @media screen and (max-width: 1278px) {
      #allath,#chatcontainer{
       width: 61vw;
      }    
    }
@media screen and (max-width: 1245px) {
    #allath,#chatcontainer{
     width: 60vw;
    }    
  }
  
@media screen and (max-width: 1135px) {
    #allath,#chatcontainer{
     width: 70vw;
    }    
  }
@media screen and (max-width: 963px) {
    #allath{
      width: 95vw !important;
      position: relative;
      margin-top: 50px !important; 
      z-index: 1;
    }  
  }
.allAthletes,
.InviteAthlete {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

.allAthletes__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.inviteAthlete__info {
    display: flex;
    align-items: center;
    width: 100%;
}
.allAthletes__info > h1,
.inviteAthlete__info > h1 {
    font-size: 24px;
    font-weight: 600;
    margin-right: 60%;
}

.allAthletes__backButton > img,
.inviteAthlete__backButton > img {
    object-fit: contain;
    margin: 0 20px;
}

.allAthletes__inviteAthletesButton {
    display: flex;
    align-items: center;
    margin-right: 20px;
    background-color: var(--primarycolor);
    border-radius: 16px;
}
.allAthletes__backButton,
.inviteAthlete__backButton,
.allAthletes__inviteAthletesButton {
    cursor: pointer;
}

.allAthletes__athletes {
    justify-content: space-between;
    display: flex;
    background-color: white;
    box-sizing: border-box;
    align-items: center;
    border-radius: 5px;
    margin: 20px 20px 5px 20px;
    padding: 5px 18px;
}

.allAthletes__athleteslogo {
    border-radius: 20px;
}

.allAthletes__athletes > h1 {
    font-size: 18px;
    font-weight: 500;
    /* line-height: 24px; */
}

.inviteAthlete__form {
    display: flex;
    flex-direction: column;
}

.inviteAthlete__form > h2 {
    margin-bottom: 1px;
    font-size: 26px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 2%;
}

.inviteAthlete__form > h3 {
    font-size: 18px;
    font-weight: 600;
    line-height: 24px;
    margin-left: 2%;
    color: gray;
}

.inviteAthlete__form > input {
    padding: 10px;
    display: flex;
    width: 90%;
    border: none;
    outline: gray;
    background-color: white;
    margin-left: 2%;
}

.inviteAthlete__sendMailButton,
.inviteAthlete__shareInvite {
    margin-left: 2%;
    margin-top: 20px;
    justify-content: center;
    display: flex;
    align-items: center;
    width: 90%;
    background-color: var(--primarycolor);
    color: var(--secondarycolor);
    border-radius: 10px;
    height: 25px;
    padding: 8px 20px;
    cursor:pointer;
}

.shareInvite {
    margin-top: 30px;
    padding: 10px;
    display: flex;
    width: 70%;
    border: none;
    outline: gray;
    background-color: white;
    margin-left: 2%;
    height: 100px;
}

div h1:hover {
    cursor: pointer;
}
