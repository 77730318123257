.login__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    min-height: 100vh;
}

.form {
    width: 230px;
    padding: 10px;
}

.form-group {
    display: flex;
    flex-direction: column;
    margin-bottom: 0.4rem;
}

.input-field {
    border-radius: 0.3rem;
    border: 0.12rem solid grey;
    height: 1.5rem;
    margin: 0.3rem 0;
    width: 100%;
    outline-color: var(--primarycolor);
    padding: 3px 10px;
}

.login__button {
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;
    border-radius: 0.5rem;
    width: 250px;
    height: 30px;
    border: 0.12rem solid transparent;
    background-color: var(--primarycolor);
    color: var(--secondarycolor);
    cursor: pointer;
}
.loading__img {
    padding: 0;
    margin: 0;
}
.login__heading {
    margin: 0.3rem 0;
    padding: 0;
}

.signup-link {
    text-decoration: underline;
    color: blue;
}
