.coachNutritionHome,
.coachAddMeal,
.athleteAddMeal,
.athleteNutritionHome,
#assignworkouts,
.createNutrition {
  margin-top: 20px;
}

@media screen and (max-width: 2000px) {
  div.eachWeek {
    width: 58vw !important;
  }
  img#rightarrow {
    position: relative;
    right: 0px !important;
  }
  img#leftarrow {
    position: relative;
    left: 12px !important;
  }
  div.leftweek {
    position: relative;
    left: 20px !important;
  }
}
@media screen and (max-width: 1782px) {
  div.eachWeek {
    width: 55vw !important;
  }

  img#rightarrow {
    position: relative;
    right: -27px !important;
  }
  img#leftarrow {
    position: relative;
    left: 2px !important;
  }
  div.leftweek {
    position: relative;
    left: 30px !important;
  }
}
@media screen and (max-width: 1690px) {
  div.longtermworkoutbackground {
    width: 320px !important;
  }
  div.longtermworkoutnew {
    width: 220px !important;
  }
  div.longtermworkoutday {
    width: 240px !important;
  }
  img#rightarrow {
    position: relative;
    right: 10px !important;
  }
  img#leftarrow {
    position: relative;
    left: 0px !important;
  }
  div.leftweek {
    position: relative;
    left: 20px !important;
  }
}

@media screen and (max-width: 1610px) {
  #rightarrow {
    position: relative;
    right: -2px !important;
  }
  img#leftarrow {
    position: relative;
    left: 0px !important;
  }
  div.leftweek {
    position: relative;
    left: 0px !important;
  }
  #backdivwkt{
    width: 115% !important;
  }
}
@media screen and (max-width: 1555px) {
  div.eachWeek {
    width: 58vw !important;
  }
  img#rightarrow {
    position: relative;
    right: 12px !important;
  }
  img#leftarrow {
    position: relative;
    left: 5px !important;
  }
  div.leftweek {
    position: relative;
    left: 20px !important;
  }
}
@media screen and (max-width: 1537px) {
  div.eachWeek {
    width: 58vw !important;
  }
  #rightarrow {
    position: relative;
    right: -12px !important;
  }
  img#leftarrow {
    position: relative;
    left: 5px !important;
  }
  div.leftweek {
    position: relative;
    left: 10px !important;
  }
}
@media screen and (max-width: 1486px) {
  div.eachWeek {
    width: 60vw !important;
  }
}

@media screen and (max-width: 1418px) {
  div.eachWeek {
    width: 55vw !important;
  }
  div.longtermworkoutbackground {
    width: 300px !important;
  }
  div.longtermworkoutnew {
    width: 200px !important;
  }
  div.longtermworkoutday {
    width: 220px !important;
  }
  img#rightarrow {
    position: relative;
    right: 10px !important;
  }
  img#leftarrow {
    position: relative;
    left: 1px !important;
  }
  div.leftweek {
    position: relative;
    left: 20px !important;
  }
  .nutbtnwktleft{
    margin-left: 30px !important;
  }
  .nutdate{
    position: relative;
    right: 12px;
  }
 
}
@media screen and (max-width: 1355px) {
  #insidescrollwkt{
    width: 34px !important;
  }
}
@media screen and (max-width: 1300px) {
  div.eachWeek {
    width: 57vw !important;
  }
  div.longtermworkoutbackground {
    width: 300px !important;
  }
  div.longtermworkoutnew {
    width: 230px !important;
  }
  div.longtermworkoutday { 
    width: 250px !important;
  }
  img#rightarrow {
    position: relative;
    right: -5px !important;
  }
  div.leftweek {
    position: relative;
    left: 10px !important;
  }
  #leftarrow {
    left: 5px !important;
  }
}
@media screen and (max-width: 1200px) {
  div.leftweek {
    position: relative;
    left: 20px !important;
  }
  div.eachWeek {
    width: 55vw !important;
  }
  div.longtermworkoutbackground {
    width: 270px !important;
  }
  div.longtermworkoutnew {
    position: relative;
    left: 20px !important;
    width: 200px !important;
  }
  div.longtermworkoutday {
    width: 210px !important;
  }
  img#rightarrow {
    position: relative;
    right: -11px !important;
  }
}
@media screen and (max-width: 1135px) {
  #insidescrollwkt{
    width: 25px !important;
  }
  .rightbtnwkt{
    position: relative;
    left: 10px !important;
  }
  div.eachWeek {
    width: 68vw !important;
  }
  div.longtermworkoutbackground {
    width: 330px !important;
  }
  div.longtermworkoutnew {
    width: 240px !important;
  }
  div.longtermworkoutday {
    width: 250px !important;
  }

  #rightarrow {
    position: relative;
    right: -10px !important;
  }
}
@media screen and (max-width: 1100px) {
  div.leftweek {
    position: relative;
    left: 20px;
  }
  div.eachWeek {
    width: 69vw !important;
  }
  div.longtermworkoutbackground {
    width: 300px !important;
  }
  div.longtermworkoutnew {
    position: relative;
    left: 20px !important;
    width: 210px !important;
  }
  div.longtermworkoutday {
    width: 220px !important;
  }
  img#rightarrow {
    position: relative;
    right: 1px !important;
  }
}
@media screen and (max-width: 1000px) {
  div.leftweek {
    position: relative;
    left: 20px;
  }
  div.eachWeek {
    width: 69vw !important;
  }
  div.longtermworkoutbackground {
    width: 280px !important;
  }
  div.longtermworkoutnew {
    position: relative;
    left: 20px !important;
    width: 190px !important;
  }
  div.longtermworkoutday {
    width: 200px !important;
  }
  img#rightarrow {
    position: relative;
    right: -5px !important;
  }
}
@media screen and (max-width: 963px) {
  #longtermworkout {
    width: 90vw !important;
  }
  div.inputworkout {
    width: 80vw !important;
  }
  div.eachWeek {
    width: 90vw !important;
  }
  div.longtermworkoutbackground {
    width: 34vw !important;
  }
  div.longtermworkoutnew {
    position: relative;
    left: 20px !important;
    width: 260px !important;
  }
  div.longtermworkoutday {
    width: 28vw !important;
  }
  img#rightarrow {
    right: 10px !important;
  }
}
@media (min-width: 1800px) and (max-width: 2000px) {
  .coachNutritionHome {
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media (min-width: 1550px) and (max-width: 1800px) {
  .coachNutritionHome {
    width: 58vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media (min-width: 1400px) and (max-width: 1550px) {
  .coachNutritionHome {
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1400px) {
  .athleteNutrition {
    width: 58vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1419px) {
  .athleteNutrition {
    width: 62vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1485px) {
  .athleteNutrition {
    width: 59vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1500px) {
  .athleteNutrition {
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1550px) {
  .athleteNutrition {
    width: 55vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1600px) {
  .athleteNutrition {
    width: 57vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (min-width: 1766px) {
  .athleteNutrition {
    width: 59vw !important;
    padding-left: 1% !important;
    padding-right: 0% !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1800px) {
  .athleteNutrition {
    width: 60vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (min-width: 1910px) {
  .athleteNutrition {
    width: 62vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (max-width: 963px) {
  .coachAddMeal,
  .createNutrition,
  .variableclass,
  .athleteAddMeal {
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 50px;
  }
  #nutid,
  #assignworkout {
    margin-top: 50px !important;
  }
}
@media screen and (max-width: 963px) {
  .workouts__home,
  .coachCreateWorkout,
  #savedworkouts {
    width: 94vw !important;
    padding-left: 1% !important;
    padding-right: 1% !important;
    position: relative;
    margin-top: 50px;
  }
  #inputtag {
    width: 92vw !important;
  }

  #inputtagg {
    width: 92vw !important;
  }
}
.nutritionScreenHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 95%;
}

.nutritionHeader__info {
  display: flex;
  align-items: center;
}

.nutritionHeader__info > h1 {
  font-size: 24px;
  font-weight: 600;
}

.addNutrition__button {
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: var(--primarycolor);
  color: var(--secondarycolor);
  border-radius: 20px;
  height: 20px;
  padding: 5px 20px;
  cursor: pointer;
  margin-top: 20px;
}

.custom-today-day {
  color: var(--primarycolor) !important;
  background-color: black !important;
  border: none;
  margin-bottom: none;
  box-shadow: 1px solid var(--primarycolor) !important;
  font-style: none;
}
.custom-today-day::after {
  visibility: hidden;
}

.customcalendarScreen {
  border-radius: 10px;
  min-width: 380px !important;
}

.customcalendarAddGoal {
  min-width: 350px !important;
  margin-left: -10px;
  box-shadow: none;
}

.assignedNutrition__button {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 20px;
  background-color: var(--primarycolor);
  border-radius: 20px;
  height: 20px;
  padding: 10px 20px;
  width: 70%;
  margin-left: 10%;
  cursor: pointer;
}

.addNutrition__button > img {
  margin-right: 8px;
}

.addNutrition__button > h5 {
  font-size: 12px;
  font-weight: 600;
}

.nutritionHeader__backButton > img {
  object-fit: contain;
  margin: 0 20px;
}

.no-upcoming-food-text {
  font-size: 12px;
  font-weight: 400;
  background-color: #fff;
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border-radius: 5px;
}

.assignedByCoach__Card {
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  background-color: #fff;
  margin: 10px 0px;
  padding: 10px;
  border-radius: 5px;
}

.assignedByCoach__CardInfo > h4 {
  font-size: 12px;
}

.assignByCoach__CardInfoDates > h4 {
  font-size: 10px;
}

.foodCard__description {
  margin-top: 20px;
  font-size: 15px;
  background-color: white;
  border-radius: 5px;
  padding: 10px 0 10px 10px;
  display: flex;
  border: 1px solid black;
  outline: gray;
  height: 100px;
  width: 100%;
  box-sizing: border-box;
}

.coachAddMeal__header > img,
.createNutrition > img,
.assignedNutrition > img {
  padding-top: 30px;
  object-fit: contain;
  margin-left: 16%;
  justify-content: center;
  height: 40%;
  width: 60%;
}

.coachAddMeal__input,
.createNutrition__input {
  padding-left: 30px;
}

.coachAddMeal__input > h4,
.coachAddMeal__textArea > h4,
.createNutrition__input > h4,
.assignedNutrition > h4 {
  font-size: 18px;
  font-weight: 600;
  line-height: 30px;
}

.coachAddMeal__input > input,
.createNutrition__input > input {
  padding: 10px;
  display: flex;
  box-sizing: border-box;
  width: 100%;
  outline: gray;
  background-color: white;
  border: 1px solid #777;
}

.coachAddMeal__textArea > textarea {
  background-color: white;
  border: 1px solid #777;
  padding: 10px;
  display: flex;
  width: 100%;
  outline: gray;
  height: 80px;
  box-sizing: border-box;
}

.coachAddMeal__textArea {
  width: 100%;
  padding-bottom: 30px;
  box-sizing: border-box;
}

.assignedMealPlans__headings,
.savedMealPlans__headings {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px;
}

.assignedMealPlans__headings > h3,
.savedMealPlans__headings > h3 {
  font-size: 22px;
  font-weight: 600;
  line-height: 28px;
}

.assignedMealPlans__headings > h4,
.savedMealPlans__headings > h4 {
  font-size: 16px;
  font-weight: 500;
  line-height: 17px;
  color: gray;
}

.assignedMealPlans__headings > h4:hover,
.savedMealPlans__headings > h4:hover {
  cursor: pointer;
}

.athleteAddMeal__typeOfMeal > h2 {
  font-size: 20px;
  font-weight: 500;
  line-height: 22px;
  margin-left: 3%;
}

.nutritionHeader__backButton:hover {
  cursor: pointer;
}

.assignedNutrition > h4 {
  margin-left: 4%;
}

.coachNutrition__homeContainer {
  display: flex;
  align-items: flex-start;
  flex: 1;
}

.coachNutrition__homeLeftContainer,
.coachNutrition__homeRightContainer {
  flex: 0.45;
  padding: 10px;
}

.coachNutrition__row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-right: 5px;
}

.coachNutrition__row > div {
  cursor: pointer;
  padding: 0 5px;
  font-size: 12px;
  text-align: center;
}
.coachNutrition__row > div:hover {
  background-color: #e6e6e6;
  border-radius: 5px;
}

.coachNutrition__row > h1,
.coachNutrition__row > h1,
.coachNutrition__homeRightContainer > h1 {
  font-size: 16px;
  font-weight: 500;
}

/*
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected {
  background-color: white;
  color: black;
}
.react-datepicker__day {
  background-color: white;
}
.react-datepicker__day:hover {
  background-color: #d1b962;
  border-radius: 10px;
  height: 2.7rem;
  cursor: pointer;

  color: black;
  box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.1);
}
.react-datepicker__day--selected {
  background-color: var(--primarycolor);
  border-radius: 10px;
  height: 2.7rem;

  color: black;
  box-shadow: 0px 0px 2px 1px rgba(0, 0, 0, 0.1);
}
.react-datepicker__current-month {
  font-weight: 400;
}
.react-datepicker__day--selected:hover {
  background-color: #d1b962;
}
.react-datepicker__day-name {
  color: #a8a8a8;
}
.react-datepicker__header {
  background-color: white;
  border-bottom: none;
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  width: 2.5rem;
  line-height: 3rem;
}


.custom-calendar {
  box-shadow: 0 1em 3em rgba(156, 136, 255, 0.2);
}

.Calendar__day {
  border-radius: 10px !important;
  border: 1px solid white;
}

.Calendar__day.-selected {
  border-radius: 10px;
  color: black;
  background-color: red;
}

.-ltr:hover {
  border-radius: 10px;
}


.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border-radius: 10px;
  background-color: white;
}

.custom-today-day {
  color: black !important;
  background-color: var(--primarycolor);
  border: 1px solid var(--primarycolor);
  box-shadow: 1px solid var(--primarycolor) !important;
}
.custom-today-day:hover {
  color: black !important;
  background-color: #ccad3b;
  border: 1px solid var(--primarycolor) !important;
}
.Calendar__day:hover {
  color: black !important;
  border-radius: 10px;

  border: 1px solid #ccad3b !important;
}
.Calendar__yearText {
  font-weight: bold;
}
.Calendar__monthText {
  font-weight: 100;
  font-size: 18px;
}

.custom-today-day::after {
  visibility: hidden; 
}

*/
.customcalendarScreen {
  border-radius: 10px;
  min-width: 380px !important;
}
.customcalendarAddGoal {
  min-width: 350px !important;
  margin-left: -10px;
  box-shadow: none;
}
.eventsContainerScreen {
  width: 380px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 25px;
  background-color: white;

  font-size: 15px;
  box-shadow: none;
  box-sizing: border-box;
}
.Calendar {
  width: 350px !important;
}
.custom-calendar {
  border-radius: 10px;
  min-width: 350px !important;
}
.Calendar__monthArrow {
  background-image: url("https://cdn0.iconfinder.com/data/icons/glyphpack/26/nav-arrow-left-512.png") !important;
  width: 7px !important;
  height: 12px !important;
  transform: rotate(90deg) !important;
}

.custom-today-day {
  color: var(--primarycolor) !important;
  background-color: black !important;
  border: none;
  margin-bottom: none;
  box-shadow: 1px solid var(--primarycolor) !important;
  font-style: none;
}
.custom-today-day::after {
  visibility: hidden;
}
.Calendar__day.-ltr {
  min-height: 3.1em !important;
}
.Calendar__day,
.Calendar__day:hover {
  border-radius: 10px !important;
}
.Calendar__day:not(.-blank):not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween):not(.-selected):hover {
  border: 1px solid #dab945 !important;
  background-color: white !important;
  color: black !important;
}
.Calendar__monthText {
  font-weight: 100 !important;
  padding: 0 !important;
}

.Calendar__day.-selected::after {
  content: attr(week);
  font-size: 10px;
}

.Calendar__monthYear.-shown {
  padding: 0 !important;
}

.Calendar__yearText {
  padding: 0 !important;
  font-weight: bold;
}

.Calendar__day.-selected {
  display: flex;
  flex-direction: column;
  color: black !important;
}
.calendar_right {
  margin-left: auto;
}
.calendar_header {
  display: flex;
  align-items: center;
}
.calendar_container {
  padding: 10px;
}
.calendar_title {
  font-size: 20px;
  font-weight: 400;
}
.add_event {
  background-color: var(--primarycolor);
  border: none;
  font-size: 25px;
  margin: 10px;
  font-weight: 100;
  border-radius: 3px;
  padding-right: 10px;
  padding-left: 10px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  color: var(--secondarycolor);
}

.events_container {
  width: 350px;
  margin-top: 20px;
  border-radius: 10px;
  padding: 25px;
  background-color: white;

  font-size: 15px;
  box-shadow: 0 1em 4em rgb(0 0 0 / 7%);
  box-sizing: border-box;
}
.events_today_list {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}
.events_today_ {
  font-size: 17px;
  font-weight: 600;
}
.events_today_arrow {
  margin-left: auto;
}
.divider {
  margin-top: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.upcoming_event_right button {
  border: none;
  background-color: var(--primarycolor);
  margin: 2px;
  font-size: 12px;
  font-weight: 100;
  border-radius: 3px;
  box-shadow: 0px 0px 1px 1px rgba(0, 0, 0, 0.1);
}
.upcoming_event_right {
  display: flex;
}

.events_more {
  cursor: pointer;
}

.assign-nutrition .athleteAddMealfood__container {
  margin: 20px 30px;
}
