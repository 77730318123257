@media screen and (max-width: 1400px) {
  .extraclass {
    width: 650px !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1200px) {
  .extraclass {
    width: 600px !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (max-width: 1134px) {
  .extraclass {
    width: 650px !important;
    position: relative;
    margin-top: 20px;
  }
}

@media screen and (max-width: 1020px) {
  .extraclass {
    width: 620px !important;
    position: relative;
    margin-top: 20px;
  }
}
@media screen and (max-width: 963px) {
  .extraclass {
    max-width: 90vw !important;
    padding-left: 2% !important;
    padding-right: 2% !important;
    position: relative;
    margin-top: 20px;
  }
}
.workoutCard {
  display: flex;
  align-items: center;
  padding: 20px;
  width: 90%;
  height: 80px;
  background-color: white;
  border-radius: 10px;
  margin: 8px 0;
  cursor: pointer;
}

.workoutCard > img {
  object-fit: contain;
}

.workoutCard__info {
  display: flex;
  flex-direction: column;
}

.workoutCard__info > h1 {
  font-size: 16px;
  font-weight: 600;
}

.workoutCard__macroNutrients {
  width: 60%;
  display: flex;
  align-items: center;
  height: 20px;
}

.workoutCard__macroNutrients > h3 {
  width: 100px;
  font-size: 12px;
  font-weight: 500;
}

.right__arrow {
  width: 20px;
  height: 20px;
  opacity: 0.8;
  object-fit: cover;
}
